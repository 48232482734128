import {
	TabsContent,
	TabsIndicator,
	TabsList,
	TabsRoot,
	TabsTrigger,
	Text,
} from '@jam/front-library';
import { useTranslation } from 'react-i18next';
import { AppLayout } from '../common/components/AppLayout';
import { Salutation } from '../features/home/components/Salutation';
import { PerformanceByMember } from '../features/performance/components/PerformanceByMember';
import { PerformanceByMission } from '../features/performance/components/PerformanceByMission';
import { PerformanceByTask } from '../features/performance/components/PerformanceByTask';
import { TeamPerformanceChart } from '../features/performance/components/TeamPerformanceChart';

export const PerformancePage = () => {
	const { t } = useTranslation('performance');
	return (
		<AppLayout>
			<Salutation text={t('performance')} />
			<Text color="#757575" fontSize="16px">
				{t('performance_description')}
			</Text>
			<TabsRoot mt="8" lazyMount defaultValue="performance-chart">
				<TabsList>
					<TabsTrigger value="performance-chart">
						{t('performance_chart')}
					</TabsTrigger>
					<TabsTrigger value="by-member">{t('by_member')}</TabsTrigger>
					<TabsTrigger value="by-mission">{t('by_mission')}</TabsTrigger>
					<TabsTrigger value="by-task">{t('by_task')}</TabsTrigger>
					<TabsIndicator />
				</TabsList>
				<TabsContent value="performance-chart" px="0" py="8">
					<TeamPerformanceChart />
				</TabsContent>
				<TabsContent value="by-member" px="0" py="8">
					<PerformanceByMember />
				</TabsContent>
				<TabsContent value="by-mission" px="0" py="8">
					<PerformanceByMission />
				</TabsContent>
				<TabsContent value="by-task" px="0" py="8">
					<PerformanceByTask />
				</TabsContent>
			</TabsRoot>
		</AppLayout>
	);
};
