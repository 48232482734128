import { Box, Flex, Text } from '@chakra-ui/react';
import { SchemaOrganizationResponseDto } from '@jam/api-sdk/api';
import {
	Button,
	Chip,
	SkeletonText,
	Table,
	usePrepareTableServerSide,
} from '@jam/front-library';
import { Plus } from '@phosphor-icons/react';
import { createColumnHelper } from '@tanstack/react-table';
import {
	useGetOrganizationsQuery,
	useGetSagasQuery,
} from '../../redux/api/adminApi';
import { useEffect } from 'react';

const LoadingState = () => {
	return (
		<Flex role="placeholder" mt="5" direction={'column'} gap="6">
			<Box maxW="300px">
				<SkeletonText noOfLines={1} height={'30px'} />
			</Box>
			<SkeletonText w={'full'} noOfLines={5} gap={'5'} />
		</Flex>
	);
};

export const OrganizationTable = ({
	onRowClick,
	onAddOrg,
}: {
	onRowClick?: (row: SchemaOrganizationResponseDto) => void;
	onAddOrg?: () => void;
}) => {
	const { data: sagasData, isLoading: sagaIsLoading } = useGetSagasQuery();

	const { page, limit, query, data, serverSideProps, onDataUpdated } =
		usePrepareTableServerSide<SchemaOrganizationResponseDto>();

	const { data: orgData, isLoading } = useGetOrganizationsQuery({
		limit,
		page,
		...query,
	});

	useEffect(() => {
		onDataUpdated(orgData);
	}, [orgData]);

	if (isLoading || sagaIsLoading) return <LoadingState />;

	if (!data || !sagasData) return null;

	const columnHelper = createColumnHelper<SchemaOrganizationResponseDto>();
	const columns = [
		columnHelper.accessor('name', {
			header: 'Name',
			cell: ({ row }) => <Text fontWeight={'medium'}>{row.original.name}</Text>,
			meta: {
				name: 'organizationNameSearch',
				filtering: {
					backendName: 'organizationNameSearch',
					variant: 'text',
					placeholder: 'Organization Name',
				},
			},
		}),
		columnHelper.display({
			header: 'Sagas',
			cell: ({ row }) => {
				const sagas = row.original.sagaIds.map((sagaId) => {
					const saga = sagasData.find((s) => s.id === sagaId);

					return saga?.name ?? sagaId;
				});

				return (
					<>
						{sagas.map((saga, index) => (
							<Chip key={index} text={saga} />
						))}
					</>
				);
			},
		}),
	];

	return (
		<Table<SchemaOrganizationResponseDto>
			asServerSide
			serverSideProps={serverSideProps}
			data={data}
			columns={columns}
			actions={
				onAddOrg
					? [
							<Button
								key={'add-org-button'}
								id="add-org-button"
								type="submit"
								onClick={onAddOrg}
								icon={<Plus size={15} />}
							>
								Add organization
							</Button>,
						]
					: []
			}
			enablePagination
			onRowClick={(item) => {
				onRowClick?.(item);
			}}
		/>
	);
};
