import { HStack, Stack, Textarea } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	Button,
	DialogBackdrop,
	DialogBody,
	DialogCloseTrigger,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogRoot,
	DialogTitle,
	Field,
	Radio,
	RadioGroup,
	toaster,
} from '@jam/front-library';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCreateStoryUserFeedbackMutation } from '../../../redux/api/userFeedbackApi';
import { UserFeedback, UserFeedbackSchema } from '../types/FormTypes';

type UserFeedbackModalProps = {
	storyId: string;
	isOpen: boolean;
	onClose: () => void;
};

const UserFeedbackModal = ({
	isOpen,
	onClose,
	storyId,
}: UserFeedbackModalProps) => {
	const [addUserFeedback] = useCreateStoryUserFeedbackMutation();
	const { t } = useTranslation(['call', 'common']);
	const {
		register,
		handleSubmit,
		control,
		formState: { isValid },
	} = useForm<UserFeedback>({
		resolver: zodResolver(UserFeedbackSchema),
	});

	const onSubmit = async (data: UserFeedback) => {
		await addUserFeedback({
			storyReferenceId: storyId,
			rating: Number(data.rating),
			content: data.feedback,
		});
		onClose();
		toaster.create({
			title: t('call_feedback_success_title'),
			type: 'success',
			duration: 3000,
			meta: {
				closable: true,
			},
		});
	};

	return (
		<DialogRoot
			size={'lg'}
			open={isOpen}
			onOpenChange={({ open }) => {
				if (!open) {
					onClose();
				}
			}}
		>
			<DialogBackdrop />
			<DialogContent>
				<DialogHeader fontSize={'18px'}>
					<DialogTitle>{t('call_feedback_modal_title')}</DialogTitle>
				</DialogHeader>
				<DialogCloseTrigger />
				<DialogBody pb={6}>
					<Stack gap={8}>
						<Field
							as="fieldset"
							label={t('call_feedback_rating_label')}
							helperText={t('call_feedback_rating_helper')}
						>
							<Controller
								control={control}
								name="rating"
								render={({ field }) => (
									<RadioGroup {...field}>
										<HStack gap="24px">
											<Radio value="1">1</Radio>
											<Radio value="2">2</Radio>
											<Radio value="3">3</Radio>
											<Radio value="4">4</Radio>
											<Radio value="5">5</Radio>
										</HStack>
									</RadioGroup>
								)}
							/>
						</Field>
						<Field
							as="fieldset"
							label={t('call_feedback_text_label')}
							helperText={t('call_feedback_text_helper')}
						>
							<Textarea {...register('feedback')} size="sm" />
						</Field>
					</Stack>
				</DialogBody>
				<DialogFooter>
					<Button
						id="submit-feedback-button"
						type="submit"
						disabled={!isValid}
						onClick={() => {
							void handleSubmit(onSubmit)();
						}}
					>
						{t('common:submit')}
					</Button>
				</DialogFooter>
			</DialogContent>
		</DialogRoot>
	);
};

export default UserFeedbackModal;
