import { Flex, Text } from '@chakra-ui/react';
import { SchemaMissionPerformanceResponseDto } from '@jam/api-sdk/api';
import { Avatar, Table } from '@jam/front-library';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { MissionDetailDots } from '../../../common/components/custom-table/cells/manager/MissionDetailDots';
import { useGetTeamPerformanceByMissionQuery } from '../../../redux/api/dashboardApi';

type TableData = SchemaMissionPerformanceResponseDto & {
	completionPercentage: number | null;
};

export const LoadingState = () => {
	return <div>Loading...</div>;
};

export const PerformanceByMission = () => {
	const { t } = useTranslation('performance');
	const { data, isLoading } = useGetTeamPerformanceByMissionQuery();
	const columnHelper = createColumnHelper<TableData>();

	if (isLoading) {
		return <LoadingState />;
	}

	if (!data || data.length === 0) {
		return <div>No data</div>;
	}

	const tracks = [...new Set(data.map((performance) => performance.trackName))];

	const columns = [
		columnHelper.accessor('missionName', {
			header: t('performance_by_mission.mission') ?? '',
			cell: (info) => {
				return (
					<Flex alignItems={'center'} gap="2">
						<Avatar size="sm" src={info.row.original.personaImage ?? ''} />
						<Flex direction={'column'}>
							<Text>{info.getValue()}</Text>
							<Text fontSize="12px" color="#757575">
								{info.row.original.personaName}
							</Text>
						</Flex>
					</Flex>
				);
			},
			meta: {
				name: 'missionName',
				filtering: {
					variant: 'text',
					placeholder:
						t('performance_by_mission.search_mission_placeholder') ?? '',
				},
			},
		}),
		columnHelper.accessor('trackName', {
			header: t('performance_by_mission.track') ?? '',
			cell: (info) => info.getValue() ?? '-',
			meta: {
				name: 'trackName',
				filtering: {
					variant: 'select',
					placeholder: t('performance_by_mission.all_tracks') ?? '',
					options: [
						{
							label: t('performance_by_mission.all_tracks'),
							value: '',
						},
						...tracks.map((track) => ({
							label: track,
							value: track,
						})),
					],
				},
			},
		}),
		columnHelper.accessor('roundsPlayed', {
			header: t('performance_by_mission.rounds_played') ?? '',
			cell: (info) => info.getValue() ?? '-',
		}),
		columnHelper.accessor('completionPercentage', {
			header: t('performance_by_mission.completions_rounds') ?? '',
			cell: (info) =>
				`${info.row.original.roundsCompleted} / ${info.row.original.roundsPlayed}`,
		}),
		columnHelper.accessor('averageScore', {
			header: t('performance_by_mission.average_score') ?? '',
			cell: (info) => info.getValue() ?? '-',
		}),
		columnHelper.accessor('averageRoundToCompletion', {
			header: t('performance_by_mission.rounds_to_complete') ?? '',
			cell: (info) => info.getValue() ?? '-',
		}),
		columnHelper.display({
			id: 'dots',
			cell: ({ row }) => {
				return (
					<MissionDetailDots
						missionId={row.original.missionId}
						trackId={row.original.trackId}
					/>
				);
			},
		}),
	];

	const tableData = data.map((performance) => ({
		...performance,
		completionPercentage:
			performance.roundsPlayed === 0
				? null
				: (performance.roundsCompleted / performance.roundsPlayed) * 100,
	}));

	return (
		<Table<TableData>
			data={tableData}
			defaultSorting={[
				{
					id: 'averageScore',
					desc: false,
				},
			]}
			columns={columns}
			enablePagination
		/>
	);
};
