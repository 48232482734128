import { Flex, Separator, Text } from '@chakra-ui/react';

type MenuDividerProps = {
	hidden?: boolean;
	text: string;
};

export const MenuDivider = ({ hidden, text }: MenuDividerProps) => {
	return (
		<Flex
			hidden={!!hidden}
			height={'14px'}
			my="2"
			px="3"
			gap={1}
			alignItems={'center'}
		>
			{text && (
				<Text color="#757575" fontSize="12px">
					{text}
				</Text>
			)}
			<Separator color="#757575" />
		</Flex>
	);
};
