import { Flex } from '@chakra-ui/react';
import { Avatar, Chip } from '@jam/front-library';
import { useAuthInfo } from '@propelauth/react';
import { UserContextMenu } from '../../features/call/components/UserContextMenu';
import { useGetUserTotalExperienceQuery } from '../../redux/api/sessionApi';
import { XpIcon } from './Icons';

export const UserWidgets = () => {
	const { user: authUser } = useAuthInfo();
	const { data: exp } = useGetUserTotalExperienceQuery();
	return (
		<Flex gap={2} alignItems={'center'}>
			{typeof exp === 'number' && exp >= 0 && (
				<Chip
					aria-label="Experience points"
					icon={<XpIcon height="1em" width="1em" />}
					h="32px"
					border={0}
					bg={'white'}
					fontWeight={'500'}
					gap={1}
					color={'black'}
					text={`${exp}`}
				/>
			)}
			<UserContextMenu placement="bottom-start" ml="2">
				<Avatar
					aria-label="Profile"
					cursor={'pointer'}
					size="sm"
					src={authUser?.pictureUrl}
					name={authUser?.firstName}
					bg="grey"
					border={'1px'}
				/>
			</UserContextMenu>
		</Flex>
	);
};
