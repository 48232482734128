import { Flex, Text } from '@chakra-ui/react';
import { skipToken } from '@reduxjs/toolkit/query';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetFirstNotCompletedStory } from '../../../../../common/utils/getfirstNotCompletedStory';
import { useGetAssignedContentAllocationQuery } from '../../../../../redux/api/contentAllocationApi';
import { VerticalAssignmentList } from './components/VerticalAssignmentList';

export interface CurrentAssignmentsProps {
	userId?: string;
	renderStoryCardButton: (storyId: string) => ReactElement;
}

export const CurrentAssignments = ({
	userId,
	renderStoryCardButton,
}: CurrentAssignmentsProps) => {
	const { t } = useTranslation('common');
	const { data: contentAllocations } = useGetAssignedContentAllocationQuery(
		userId ?? skipToken
	);
	const { activeStory } = useGetFirstNotCompletedStory();

	return (
		<Flex direction={'column'} gap="8" mt="8">
			<Text fontSize="20px" fontWeight="500" textAlign="left">
				{t('your_next_todos')}
			</Text>
			<VerticalAssignmentList
				userId={userId}
				contentAllocations={contentAllocations}
				activeStory={activeStory ?? undefined}
				renderStoryCardButton={renderStoryCardButton}
			/>
		</Flex>
	);
};
