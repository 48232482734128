import { Box, Circle, Flex, HStack, Skeleton } from '@chakra-ui/react';
import { SchemaUserExperienceResponseDto } from '@jam/api-sdk/api';
import {
	TabsContent,
	TabsList,
	TabsRoot,
	TabsTrigger,
	Text,
} from '@jam/front-library';
import { Trophy } from '@phosphor-icons/react';
import { useAuthInfo } from '@propelauth/react';
import { useTranslation } from 'react-i18next';
import {
	useGetOverallLeaderboardQuery,
	useGetWeeklyLeaderboardQuery,
} from '../../../redux/api/leaderboardApi';

type ListProps = {
	list: SchemaUserExperienceResponseDto[] | undefined;
	isLoading: boolean;
};

const UserListSkeleton = () => {
	return (
		<Box role="placeholder">
			<Skeleton height="24px" />
		</Box>
	);
};

const UserList = ({ list, isLoading }: ListProps) => {
	const { user: authUser } = useAuthInfo();
	if (isLoading) return <UserListSkeleton />;

	return (
		<Box>
			{list?.map((user, index) => (
				<Flex key={user.userId} align="center" py={2}>
					<HStack gap={3}>
						<Circle size="24px" bg="#EDF2F7">
							{index <= 2 ? (
								<Trophy
									color={
										index === 0
											? '#FFD700'
											: index === 1
												? '#C0C0C0'
												: '#CD7F32'
									}
								/>
							) : (
								<Text fontSize="14px" color="gray.600">
									{index + 1}
								</Text>
							)}
						</Circle>
						<Text
							fontSize={'14px'}
						>{`${user.firstName} ${user.lastName}${user.userId === authUser?.userId ? ' (me)' : ''}`}</Text>
					</HStack>
					<Text fontSize={'14px'} ml="auto">
						{user.total} XP
					</Text>
				</Flex>
			))}
		</Box>
	);
};

export const UsersLeaderboard = () => {
	const { t } = useTranslation();

	const { data: weekly, isLoading: weeklyLoading } =
		useGetWeeklyLeaderboardQuery();
	const { data: overall, isLoading: overallLoading } =
		useGetOverallLeaderboardQuery();

	return (
		<Box flexGrow={1}>
			<Text fontSize="20px" fontWeight="500" mb={4}>
				{t('leaderboard')}
			</Text>
			<TabsRoot defaultValue="all-time">
				<TabsList
					bg={'#EDF2F7'}
					h={'fit-content'}
					borderRadius={'9px'}
					p={'3px'}
					minH={'32px'}
					maxW="240px"
					minW="240px"
				>
					<TabsTrigger
						value="all-time"
						borderRadius={'7px'}
						_selected={{
							bg: 'white',
							boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
						}}
						fontSize={'14px'}
						flex="1"
						py={0}
						px={1}
						minH={'28px'}
						css={{
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						{t('all_time')}
					</TabsTrigger>
					<TabsTrigger
						value="this-week"
						borderRadius={'7px'}
						_selected={{
							bg: 'white',
							boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
						}}
						fontSize={'14px'}
						flex="1"
						py={0}
						minH={'28px'}
						px={1}
						css={{
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						{t('this_week')}
					</TabsTrigger>
				</TabsList>
				<TabsContent value="all-time" pt="1" pb={0} px={0}>
					<UserList list={overall} isLoading={overallLoading} />
				</TabsContent>
				<TabsContent value="this-week" pt={1} pb={0} px={0}>
					<UserList list={weekly} isLoading={weeklyLoading} />
				</TabsContent>
			</TabsRoot>
		</Box>
	);
};
