import { Box, Separator } from '@chakra-ui/react';
import { SchemaJourneyResponseDto } from '@jam/api-sdk/api';
import {
	AccordionItem,
	AccordionItemContent,
	AccordionItemTrigger,
	AccordionRoot,
	Text,
} from '@jam/front-library';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StoryCardGrid } from '../story-detail/StoryCardGrid';
import { TrackDetailHeader } from './TrackDetailHeader';

type TrackAccordionProps = {
	journey: SchemaJourneyResponseDto;
	completedStoryIds?: string[];
	userId?: string;
	hideStatus?: boolean;
	renderStoryButton?: (storyId: string) => ReactElement;
};

export const TrackAccordion = ({
	journey,
	completedStoryIds,
	userId,
	hideStatus,
	renderStoryButton,
}: TrackAccordionProps) => {
	const { t } = useTranslation('common');

	return (
		<Box>
			<AccordionRoot
				w={'100%'}
				borderWidth={'1.5px'}
				p={6}
				borderRadius={'12px'}
				borderColor={'gray.300'}
				collapsible
			>
				<AccordionItem border={0} value="missions">
					<TrackDetailHeader
						hideStatus={hideStatus}
						journey={journey}
						completedStoryIds={completedStoryIds}
					/>
					<Separator color="#d9d9d9" />
					<AccordionItemTrigger
						indicatorPlacement="start"
						px={0}
						paddingBottom="0px"
						paddingTop="16px"
						_hover={{ background: 'transparent' }}
					>
						<Text fontSize="16px" fontWeight="500" textColor="#4241E4">
							{t('track_detail.accordion.view_missions')}
						</Text>
					</AccordionItemTrigger>
					<AccordionItemContent px={0} py={0}>
						<StoryCardGrid
							hideStatus={hideStatus}
							userId={userId}
							stories={journey.stories}
							renderStoryButton={renderStoryButton}
						/>
					</AccordionItemContent>
				</AccordionItem>
			</AccordionRoot>
		</Box>
	);
};
