import { Input, Stack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	Button,
	DialogBackdrop,
	DialogBody,
	DialogCloseTrigger,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogRoot,
	DialogTitle,
	Field,
	Select,
	Text,
	toaster,
} from '@jam/front-library';
import { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z, ZodType } from 'zod';
import { useInviteUserToOrganizationMutation } from '../../../redux/api/crmApi';

export type AddUserModalProps = {
	isOpen: boolean;
	onClose: () => void;
};

type UserData = {
	email: string;
	role: string;
};

const UserDataSchema: ZodType<UserData> = z.object({
	email: z.string().email('Invalid email format').min(1),
	role: z.string().min(1),
});

export const AddUserModal = ({ isOpen, onClose }: AddUserModalProps) => {
	const { t } = useTranslation('settings');
	const contentRef = useRef<HTMLDivElement>(null);

	const [inviteUserToOrg] = useInviteUserToOrganizationMutation();
	const {
		register,
		handleSubmit,
		reset,
		formState: { isDirty, errors, isSubmitting },
		control,
	} = useForm<UserData>({
		resolver: zodResolver(UserDataSchema),
		defaultValues: {
			role: 'Member',
		},
	});

	const onSubmit = async (data: UserData) => {
		await inviteUserToOrg({
			email: data.email.toLowerCase(),
			role: data.role,
		});
		onClose();
		reset();
		toaster.create({
			title: t('user-invited-successfully'),
			type: 'success',
			duration: 3000,
			meta: {
				closable: true,
			},
		});
	};

	return (
		<DialogRoot
			size="lg"
			open={isOpen}
			onOpenChange={({ open }) => {
				if (!open) {
					reset();
					onClose();
				}
			}}
		>
			<DialogBackdrop />
			<DialogContent ref={contentRef}>
				<DialogHeader>
					<DialogTitle fontSize={'2xl'}>{t('add-user-title')}</DialogTitle>
					<DialogCloseTrigger />
				</DialogHeader>
				<DialogBody pb={6}>
					<Stack gap={4}>
						<Field
							invalid={!!errors.email}
							label={t('email-address')}
							errorText={errors.email?.message}
						>
							<Input borderRadius={'full'} {...register('email')} />
						</Field>
						<Controller
							control={control}
							name="role"
							render={({ field }) => (
								<Field label={t('role')}>
									<Select
										portalRef={contentRef}
										name={field.name}
										value={field.value}
										onValueChange={(value) => field.onChange(value)}
										items={[
											{ value: 'Member', label: 'Member' },
											{ value: 'Manager', label: 'Manager' },
											{ value: 'Owner', label: 'Owner' },
										]}
									/>
								</Field>
							)}
						/>
					</Stack>
				</DialogBody>
				<DialogFooter>
					<Button
						id="add-user-button"
						type="submit"
						loading={isSubmitting}
						onClick={() => {
							if (!isDirty) return;
							void handleSubmit(onSubmit)();
						}}
					>
						{t('invite')}
					</Button>
					<Button
						variant="tertiary"
						onClick={() => {
							onClose();
							reset();
						}}
					>
						<Text>{t('cancel')}</Text>
					</Button>
				</DialogFooter>
			</DialogContent>
		</DialogRoot>
	);
};
