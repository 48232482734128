import { Box, Flex, Separator, Stack } from '@chakra-ui/react';
import { SchemaCompletedUserActivityReposeDto } from '@jam/api-sdk/api';
import {
	Avatar,
	Button,
	MenuContent,
	MenuItem,
	MenuRoot,
	MenuTrigger,
	Text,
	Tooltip,
} from '@jam/front-library';
import { DotsThreeVertical, Info } from '@phosphor-icons/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type Props = {
	title: string;
	tooltip?: string;
	headers: string[];

	usersActivity:
		| SchemaCompletedUserActivityReposeDto['mostActive']
		| SchemaCompletedUserActivityReposeDto['leastActive'];
};

const LIMIT = 3;

export const UsersList = ({
	title,
	tooltip,
	headers,
	usersActivity,
}: Props) => {
	const { t } = useTranslation(['dashboard', 'common']);
	const [seeAllExpanded, setSeeAllExpanded] = useState<boolean>(false);
	const navigate = useNavigate();

	const onToggleSeeAll = () => {
		setSeeAllExpanded((prev) => !prev);
	};

	const showExpandButton = usersActivity.length > LIMIT;
	return (
		<Box
			border={'1px solid'}
			borderColor="gray.300"
			borderRadius={'16px'}
			w="100%"
			p="6"
		>
			<Stack gap={'3'} separator={<Separator />}>
				<Flex alignItems="center" w="100%" pr="3">
					<Box flexShrink={1} flex="0.6" flexDirection="row">
						<Text variant="body" fontWeight="bold" fontSize="16px">
							{title}
						</Text>
						{tooltip && (
							<Tooltip content={tooltip}>
								<Info size={20} />
							</Tooltip>
						)}
					</Box>
					{headers.map((header) => {
						return (
							<Text
								key={header}
								textAlign={'center'}
								flexShrink={0}
								flex="0.2"
								fontSize={'12px'}
								color={'#757575'}
							>
								{header}
							</Text>
						);
					})}
				</Flex>
				{usersActivity
					.slice(0, seeAllExpanded ? usersActivity.length : LIMIT)
					.map((userActivity) => {
						return (
							<Flex
								onClick={() => {
									navigate(`/dashboard/user/${userActivity.userId}`);
								}}
								cursor={'pointer'}
								alignItems="center"
								w="100%"
								key={userActivity.userId}
							>
								<Flex alignItems={'center'} flexShrink={1} flex="0.6" gap="3">
									<Avatar
										size="sm"
										name={userActivity.userName ?? 'N/A'}
										src={userActivity.userPictureUrl}
										bg="gray"
									/>
									<Box>
										<Text fontSize="16px" fontWeight="medium">
											{userActivity.userName ?? 'Unknown User'}
										</Text>
									</Box>
								</Flex>
								<Text
									textAlign={'center'}
									flexShrink={0}
									flex="0.2"
									fontSize={'30px'}
								>
									{userActivity.sessionLastWeek}
								</Text>
								<Flex justifyContent={'center'} flexShrink={0} flex="0.2">
									<Box
										w="fit-content"
										bg="#F5F5F5"
										px="2"
										py="1"
										borderRadius={'20px'}
									>
										<Text>{userActivity.sessionsTotal}</Text>
									</Box>
								</Flex>
								<MenuRoot>
									<MenuTrigger>
										<DotsThreeVertical size={15} />
									</MenuTrigger>
									<MenuContent>
										<MenuItem onClick={() => {}} value={'see-details'}>
											<Text>{t('common:see_details')}</Text>
										</MenuItem>
									</MenuContent>
								</MenuRoot>
							</Flex>
						);
					})}
				{showExpandButton && (
					<Button onClick={onToggleSeeAll} size="full" variant="tertiary">
						{seeAllExpanded ? t('dashboard:hide_all') : t('dashboard:see_all')}
					</Button>
				)}
			</Stack>
		</Box>
	);
};
