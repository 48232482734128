import {
	Book,
	ChartLine,
	ChartPieSlice,
	Flag,
	Headset,
	House,
	Phone,
	Question,
	Strategy,
	Target,
} from '@phosphor-icons/react';
import { SidebarData } from '../hooks/useAppSidebarData';
import {
	SidebarEntry,
	SidebarEntrySection,
} from '../components/side-bar/SideBarNavigation';
import { PerformanceIcon } from '../components/icons/PerformanceIcon';

const HELP_CENTER_URL =
	'https://wejam.atlassian.net/servicedesk/customer/portal/1';
const SUPPORT_EMAIL = 'support@wejam.atlassian.net';

const managerSidebarEntries: SidebarEntry<SidebarData>[] = [
	{
		label: 'sidebar.analytics.activity',
		icon: ChartPieSlice,
		hidden: ({ isOwnerOrManager }) => !isOwnerOrManager,
		href: '/dashboard',
		hrefType: 'default',
	},
	{
		label: 'sidebar.analytics.performance',
		icon: PerformanceIcon,
		hidden: ({ isOwnerOrManager }) => !isOwnerOrManager,
		href: '/performance',
		hrefType: 'default',
	},
	{
		label: 'sidebar.analytics.team',
		icon: Target,
		hidden: ({ isOwnerOrManager }) => !isOwnerOrManager,
		href: '/teams',
		hrefType: 'default',
	},
];

const learnerSidebarEntries: SidebarEntry<SidebarData>[] = [
	{
		label: 'sidebar.practice.home',
		icon: House,
		disabled: ({ isAdmin }) => isAdmin,
		href: '/users/home',
		hrefType: 'default',
	},
	{
		label: 'sidebar.practice.progress',
		icon: ChartLine,
		disabled: ({ isAdmin }) => isAdmin,
		href: '/progress',
		hrefType: 'default',
	},
	{
		label: 'sidebar.practice.course_catalog',
		icon: Book,
		hidden: ({ isRecruiting }) => isRecruiting,
		href: '/course-catalog',
		hrefType: 'default',
	},
	{
		label: 'sidebar.practice.playbooks',
		icon: Strategy,
		hidden: ({ isRecruiting }) => isRecruiting,
		href: '/journeys',
		hrefType: 'default',
	},
];

const sharedSidebarEntries: SidebarEntry<SidebarData>[] = [
	{
		label: 'sidebar.insights.my_skills',
		icon: Target,
		disabled: () => true,
		hidden: ({ isRecruiting }) => isRecruiting,
		href: '',
		hrefType: 'default',
	},
	{
		label: 'sidebar.insights.real_calls',
		icon: Phone,
		disabled: ({ isAdmin }) => !isAdmin,
		hidden: ({ isRecruiting }) => isRecruiting,
		href: '/real-call',
		hrefType: 'default',
	},
];

const helpSidebarEntries: SidebarEntry<SidebarData>[] = [
	{
		label: 'sidebar.help',
		icon: Question,
		href: HELP_CENTER_URL,
		target: '_blank',
		hrefType: 'default',
	},
	{
		label: 'sidebar.contact',
		icon: Headset,
		href: `mailto:${SUPPORT_EMAIL}`,
		hrefType: 'default',
	},
	{
		label: 'sidebar.report',
		icon: Flag,
		href: 'onReportIssue',
		hrefType: 'event',
	},
];

export const sidebarEntries: Record<
	string,
	SidebarEntrySection<SidebarData>
> = {
	learner: {
		id: 'learner-section',
		label: 'sidebar.practice.divider',
		entries: learnerSidebarEntries,
		hidden: () => false,
	},
	shared: {
		id: 'shared-section',
		label: 'sidebar.insights.divider',
		entries: sharedSidebarEntries,
		hidden: ({ isRecruiting }) => isRecruiting,
	},
	manager: {
		id: 'training-center-section',
		label: 'sidebar.analytics.divider',
		entries: managerSidebarEntries,
		hidden: ({ isOwnerOrManager }) => !isOwnerOrManager,
	},
	help: {
		id: 'help-center-section',
		entries: helpSidebarEntries,
		isFooterSection: true,
		hidden: () => false,
	},
};
