import { Box, Flex } from '@chakra-ui/react';
import { SchemaSessionResponseDto } from '@jam/api-sdk/api';
import { SkeletonText, Table, Text } from '@jam/front-library';
import { skipToken } from '@reduxjs/toolkit/query';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { SolvedItemDots } from '../../../common/components/SolvedItemDots';
import { useGetSessionsByUserIdQuery } from '../../../redux/api/sessionApi';

type HistoryItem = {
	storyTitle: string;
	storyId: string;
	sessionId: string;
	journeyTitle: string;
	date: Date;
	status: SchemaSessionResponseDto['analysis']['items'] | null;
};

const LoadingState = () => {
	return (
		<Flex role="placeholder" mt="5" direction={'column'} gap="6">
			<Box maxW="300px">
				<SkeletonText noOfLines={1} height={'30px'} />
			</Box>
			<SkeletonText w={'full'} noOfLines={5} gap={'5'} />
		</Flex>
	);
};

export const HistoryTable = ({
	userId,
	onRowClick,
}: {
	userId?: string;
	onRowClick: (row: HistoryItem) => void;
}) => {
	const { t } = useTranslation('history');
	// get all the sessions
	const { data, isLoading: sessionsLoading } = useGetSessionsByUserIdQuery(
		userId ?? skipToken,
		{
			refetchOnMountOrArgChange: true,
		}
	);

	if (sessionsLoading) return <LoadingState />;

	if (!data || data.length === 0) return null;

	const sessions: HistoryItem[] = data
		.map((session) => {
			const sortedItems = session.analysis.items;

			// return the history item object
			return {
				storyId: session.storyReferenceId,
				storyTitle: session.storyTitle,
				sessionId: session.id,
				journeyTitle: session.journeyTitle,
				date: new Date(session.createdAt),
				status: sortedItems,
			};
		})
		.filter((item) => item !== null) as HistoryItem[];

	const columnHelper = createColumnHelper<HistoryItem>();
	const columns = [
		columnHelper.accessor('storyTitle', {
			header: t('mission').toString(),
			cell: ({ row }) => <Text>{row.original.storyTitle}</Text>,
			meta: {
				name: 'storyTitle',
				filtering: {
					variant: 'select',
					placeholder: t('all_missions').toString(),
					options: sessions?.map((track) => ({
						label: track.storyTitle,
						value: track.storyTitle,
					})),
				},
			},
		}),
		columnHelper.accessor('journeyTitle', {
			header: t('track').toString(),
			cell: ({ row }) => <Text>{row.original.journeyTitle}</Text>,
		}),
		columnHelper.accessor('date', {
			header: t('date').toString(),
			cell: ({ row }) => (
				<Text variant="label">{format(row.original.date, 'MMM dd')}</Text>
			),
		}),
		columnHelper.accessor('status', {
			header: t('feedback').toString(),
			cell: ({ row }) => {
				const status = row.original.status;
				if (status === null) return null;

				const results = status.map((x) => x.solved);

				return <SolvedItemDots items={results} />;
			},
		}),
	];

	return (
		<Flex direction="column" mt="8">
			<Table<HistoryItem>
				title={t('learning_history')}
				data={sessions}
				columns={columns}
				onRowClick={(item: HistoryItem) => {
					onRowClick(item);
				}}
				enablePagination
			/>
		</Flex>
	);
};
