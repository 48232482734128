import {
	SchemaUserOverviewDto,
	PathsApiV1UsersGetParametersQueryRole,
} from '@jam/api-sdk/api';
import { toaster } from '@jam/front-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	useAddUserToTeamMutation,
	useChangeUserRoleMutation,
	useCreateTeamMutation,
	useGetTeamUsersQuery,
	useRemoveUserFromTeamMutation,
} from '../../../../../redux/api/crmApi';

export const useRegisteredMembersTable = (): {
	setSelectedTeamId: (value: string | undefined) => void;
	data: SchemaUserOverviewDto[] | undefined;
	isLoading: boolean;
	onRoleChange: (
		userId: string,
		role: PathsApiV1UsersGetParametersQueryRole
	) => Promise<void>;
	onTeamChange: (data: {
		userId: string;
		currentTeamId?: string;
		newTeamId?: string;
	}) => Promise<void>;
	onCreateTeam: (team: { name: string }) => Promise<void>;
} => {
	const { t } = useTranslation(['common', 'team']);
	const [selectedTeamId, setSelectedTeamId] = useState<string | undefined>(
		undefined
	);

	const { data, isLoading: isDataLoading } =
		useGetTeamUsersQuery(selectedTeamId);
	const [changeRole, { isLoading: isRoleChangeLoading }] =
		useChangeUserRoleMutation();
	const [addUserToTeam, { isLoading: isAddUserToTeamLoading }] =
		useAddUserToTeamMutation();
	const [removeUserFromTeam, { isLoading: isRemoveUserFromTeamLoading }] =
		useRemoveUserFromTeamMutation();
	const [createTeam, { isLoading: isCreateTeamLoading }] =
		useCreateTeamMutation();

	const onRoleChange = async (
		userId: string,
		role: PathsApiV1UsersGetParametersQueryRole
	): Promise<void> => {
		await changeRole({ userId, role });
		toaster.create({
			title: t('role-changed-successfully'),
			type: 'success',
			duration: 3000,
			meta: {
				closable: true,
			},
		});
	};

	const onTeamChange = async ({
		userId,
		currentTeamId,
		newTeamId,
	}: {
		userId: string;
		currentTeamId?: string;
		newTeamId?: string;
	}) => {
		if (currentTeamId === newTeamId) return;

		if (currentTeamId) {
			// Remove user from the team
			await removeUserFromTeam({ userId, teamId: currentTeamId });
		}

		if (newTeamId) {
			// Add user to the team
			await addUserToTeam({ userId, teamId: newTeamId });
		}
	};

	const onCreateTeam = async (team: { name: string }) => {
		await createTeam(team);
	};

	return {
		setSelectedTeamId,
		data,
		isLoading:
			isDataLoading ||
			isRoleChangeLoading ||
			isAddUserToTeamLoading ||
			isRemoveUserFromTeamLoading ||
			isCreateTeamLoading,
		onRoleChange,
		onTeamChange,
		onCreateTeam,
	};
};
