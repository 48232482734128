import { Flex, useDisclosure } from '@chakra-ui/react';
import { SchemaSessionResponseDto } from '@jam/api-sdk/api';
import { Button, toaster } from '@jam/front-library';
import { Upload } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useUploadTranscriptMutation } from '../../redux/api/sessionApi';
import { TranscriptData, UploadTranscriptModal } from './UploadTranscriptModal';

export const UploadTranscript = ({
	onTranscriptUploaded,
}: {
	onTranscriptUploaded: (
		session: SchemaSessionResponseDto
	) => void | Promise<void>;
}) => {
	const { t } = useTranslation('real-call');
	const { open: isOpen, onOpen, onClose } = useDisclosure();
	const [uploadTranscript] = useUploadTranscriptMutation();

	const onSubmitHandler = async (data: TranscriptData) => {
		const session = await uploadTranscript(data).unwrap();
		onClose();

		toaster.create({
			title: t('transcript-uploaded-successfully'),
			type: 'success',
			duration: 3000,
			meta: {
				closable: true,
			},
		});

		await onTranscriptUploaded(session);
	};

	return (
		<Flex gap="5">
			<Button
				id="upload-transcript-button"
				type="submit"
				onClick={() => {
					onOpen();
				}}
				icon={<Upload size={15} />}
			>
				{t('upload')}
			</Button>
			<UploadTranscriptModal
				isOpen={isOpen}
				onSubmit={onSubmitHandler}
				onCancel={onClose}
			/>
		</Flex>
	);
};
