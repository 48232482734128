import {
	PathsApiV1AssignmentsGetParametersQueryReferenceType,
	SchemaStoryResponseDto,
} from '@jam/api-sdk/api';
import { Button, toaster } from '@jam/front-library';
import { PushPin } from '@phosphor-icons/react';
import { skipToken } from '@reduxjs/toolkit/query';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CallBar } from '../../../common/components/CallBar';
import {
	useAssignContentMutation,
	useGetAssignedContentAllocationQuery,
} from '../../../redux/api/contentAllocationApi';

type ManagerStoryHeaderProps = {
	story?: SchemaStoryResponseDto;
	userId: string;
};

export const ManagerStoryHeader = ({
	story,
	userId,
}: ManagerStoryHeaderProps) => {
	const { t } = useTranslation('story');
	const [assignContentToUser, { isLoading }] = useAssignContentMutation();
	const { data: assignedContent } = useGetAssignedContentAllocationQuery(
		userId ?? skipToken
	);

	const onAssignContent = useCallback(async () => {
		if (!userId || !story) return;
		await assignContentToUser({
			userIds: [userId],
			reference: {
				id: story.id,
				type: PathsApiV1AssignmentsGetParametersQueryReferenceType.story,
			},
		});
		toaster.create({
			title: t('content_assigned_title'),
			description: t('content_assigned_description'),
			type: 'success',
		});
	}, [assignContentToUser, story, userId, t]);

	const assignedStoryIds = (assignedContent ?? [])
		.filter(
			(assignment) =>
				assignment.reference.type ===
				PathsApiV1AssignmentsGetParametersQueryReferenceType.story
		)
		.map((assignment) => assignment.reference.id);

	const renderStoryButton = (storyId: string) => (
		<Button
			disabled={assignedStoryIds.includes(storyId)}
			loading={isLoading}
			onClick={() => {
				void onAssignContent();
			}}
			icon={<PushPin />}
			size="lg"
		>
			{t('assign_mission')}
		</Button>
	);

	return (
		<CallBar
			story={story}
			userId={userId}
			renderStoryButton={renderStoryButton}
		/>
	);
};
