import { Stack } from '@chakra-ui/react';
import React, { ElementType } from 'react';
import { SideBarNavigationSection } from './SideBarNavigationSection';

export interface SidebarEntry<DataType> {
	label: string;
	href: string;
	hrefType: 'default' | 'event';
	target?: '_blank';
	disabled?: (data: DataType) => boolean;
	hidden?: (data: DataType) => boolean;
	icon?: ElementType;
}

export interface SidebarEntrySection<DataType> {
	id: string;
	label?: string;
	hidden: (data: DataType) => boolean;
	entries: SidebarEntry<DataType>[];
	isFooterSection?: boolean;
}

interface Props<DataType> {
	isOpen: boolean;
	items: Record<string, SidebarEntrySection<DataType>>;
	data: DataType;
	events: Record<string, () => void>;
	order?: string[];
	footer?: React.ReactNode;
}

export const SideBarNavigation = <DataType,>({
	isOpen,
	data,
	items,
	events,
	order = [],
	footer,
}: Props<DataType>) => {
	const onEventClick = (eventName: string) => {
		if (!events?.[eventName]) return;
		events[eventName]();
	};

	return (
		<Stack mt="3" h="100%" gap="4">
			{Object.entries(items)
				.sort(([key1], [key2]) => {
					const order1 = order.indexOf(key1);
					const order2 = order.indexOf(key2);
					if (order1 === -1 && order2 === -1) return 0;
					if (order1 === -1) return 1;
					if (order2 === -1) return -1;
					return order1 - order2;
				})
				.filter(([, { hidden }]) => !hidden || !hidden(data))
				.map(([key, sectionData]) => (
					<SideBarNavigationSection
						key={key}
						data={data}
						isOpen={isOpen}
						sectionKey={key}
						section={sectionData}
						onEventClick={onEventClick}
					/>
				))}
			{footer}
		</Stack>
	);
};
