import { useAuthInfo } from '@propelauth/react';
import { skipToken } from '@reduxjs/toolkit/query';
import { format } from 'date-fns';
import { de } from 'date-fns/locale/de';
import { enGB } from 'date-fns/locale/en-GB';
import { useTranslation } from 'react-i18next';
import { StartOrResumeStoryButton } from '../common/components/story-detail/buttons/StartOrResumeStoryButton';
import { useOriginPageBreadcrumbs } from '../common/hooks/useOriginPageBreadcrumbs';
import { UserDetails } from '../features/dashboard/components/user-details/UserDetails';
import { useGetDetailedUserActivityOverviewQuery } from '../redux/api/dashboardApi';
import { BasePage } from './common/BasePage';

export const ProgressPage = () => {
	const { t } = useTranslation('common');
	const { user } = useAuthInfo();
	const { i18n } = useTranslation();
	const locale = i18n.language === 'en' ? enGB : de;

	const { data, isFetching } = useGetDetailedUserActivityOverviewQuery(
		user?.userId ?? skipToken
	);

	const steps = useOriginPageBreadcrumbs(
		'progress-overview',
		'/progress',
		t('progress')
	);

	const renderStoryCardButton = (storyId: string) => (
		<StartOrResumeStoryButton storyId={storyId} />
	);

	if (isFetching || !data) {
		return null;
	}

	return (
		<BasePage
			title={data.name}
			subtitles={[
				data.registeredSince
					? t('common:joined_date', {
							date: format(new Date(data.registeredSince), 'MMM d, yyyy', {
								locale,
							}),
						})
					: '',
				data.lastSessionDate
					? t('common:last_session_date', {
							date: format(
								new Date(data.lastSessionDate ?? ''),
								'MMM d, yyyy',
								{
									locale,
								}
							),
						})
					: '',
			]}
			breadcrumbsProps={{
				items: steps,
			}}
		>
			<UserDetails
				userId={user?.userId}
				userName={data.name}
				renderStoryCardButton={renderStoryCardButton}
			/>
		</BasePage>
	);
};
