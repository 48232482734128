import { Flex } from '@chakra-ui/react';
import {
	SchemaSessionResponseDto,
	SchemaStoryResponseDto,
} from '@jam/api-sdk/api';
import {
	TabsContent,
	TabsIndicator,
	TabsList,
	TabsRoot,
	TabsTrigger,
	Text,
	Tooltip,
} from '@jam/front-library';
import { Lock } from '@phosphor-icons/react';
import { useTour } from '@reactour/tour';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsOnboarding } from '../../../common/hooks/useIsOnboarding';
import { useIsRecruiting } from '../../../common/hooks/useIsRecruiting';
import { OnboardingFeature } from '../../../common/types/Onboarding';
import { useActiveCallTabIndex } from '../hooks/useActiveCallTabIndex';
import { HintsAndGoals } from './HintsAndGoals';
import { SessionFeedback } from './SessionFeedback';
import { Transcript } from './Transcript';
import { MoreInfoTab } from './more-info/MoreInfoTab';
import { useOnboardingStepFallback } from '../../../common/hooks/useOnboardingStepFallback';

type CallDetailsProps = {
	story?: SchemaStoryResponseDto;
	session: SchemaSessionResponseDto | undefined | null;
};

export const CallDetails = ({ story, session }: CallDetailsProps) => {
	const { t } = useTranslation('call');
	const { activeCallTabIndex, setActiveCallTabIndex } = useActiveCallTabIndex();
	const { setIsOpen } = useTour();
	const isOnboarding = useIsOnboarding(OnboardingFeature.CALL_PAGE);
	const isRecruiting = useIsRecruiting();

	useOnboardingStepFallback();

	useEffect(() => {
		if (
			isOnboarding &&
			story &&
			session &&
			session.analysis &&
			session.analysis.items.length > 0
		) {
			setIsOpen(true);
		}
	}, [isOnboarding, setIsOpen, story, session]);

	return (
		<Flex gap="10">
			<TabsRoot
				lazyMount
				value={
					activeCallTabIndex === null ? 'scenario' : `tab-${activeCallTabIndex}`
				}
				onValueChange={({ value }) => {
					const index =
						value === 'scenario' ? null : parseInt(value.split('-')[1]);
					setActiveCallTabIndex(index, 'replaceIn');
				}}
			>
				<TabsList p="2" minW={'300px'}>
					<TabsTrigger value="scenario" disabled={!story}>
						{t('scenario')}
					</TabsTrigger>
					{!isRecruiting && (
						<TabsTrigger value="tab-1" disabled={!story}>
							{t('hints')}
						</TabsTrigger>
					)}
					<TabsTrigger
						value={`tab-${isRecruiting ? 1 : 2}`}
						className="feedback-tab"
						disabled={!session}
					>
						{!session ? (
							<Tooltip
								showArrow
								positioning={{ placement: 'top' }}
								content={t('complete_one_attempt_feedback')}
							>
								<Flex alignItems={'center'} gap="1">
									<Text color={'#757575'}>{t('feedback')}</Text>
									<Lock color={'#757575'} size={16} />
								</Flex>
							</Tooltip>
						) : (
							t('feedback')
						)}
					</TabsTrigger>
					<TabsIndicator />
				</TabsList>
				<Flex w="100%" gap={4}>
					<TabsContent value="scenario" pl={0} py="8" w="100%">
						<HintsAndGoals story={story} />
					</TabsContent>
					{!isRecruiting && (
						<TabsContent value="tab-1" pl={0} py="8" w="100%">
							<MoreInfoTab story={story} />
						</TabsContent>
					)}
					<TabsContent
						value={`tab-${isRecruiting ? 1 : 2}`}
						pl={0}
						py="8"
						w="100%"
					>
						<SessionFeedback session={session} />
					</TabsContent>
					<Transcript session={session} persona={story?.persona} />
				</Flex>
			</TabsRoot>
		</Flex>
	);
};
