import { Input } from '@chakra-ui/react';
import { Text } from '@jam/front-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ViewStoryButton } from '../../common/components/story-detail/buttons/ViewStoryButton';
import { TrackAccordion } from '../../common/components/track-detail/TrackAccordion';
import { useOriginPageBreadcrumbs } from '../../common/hooks/useOriginPageBreadcrumbs';
import { useGetJourneysQuery } from '../../redux/api/cmsApi';
import { BasePage } from '../common/BasePage';

export const ManagerJourneysPage = () => {
	const { t } = useTranslation('common');
	const [search, setSearch] = useState('');
	const { data: journeys } = useGetJourneysQuery();

	const filtered = (journeys ?? []).filter((journey) => {
		const title = journey.title.toLowerCase();
		return title.includes(search.toLowerCase());
	});

	const steps = useOriginPageBreadcrumbs(
		'journeys-overview',
		'/journeys',
		t('tracks')
	);

	return (
		<BasePage
			title={t('playbooks')}
			subtitles={[t('tracks_description')] as string[]}
			breadcrumbsProps={{
				items: steps,
			}}
		>
			<Input
				maxWidth={'400px'}
				value={search}
				onChange={(e) => setSearch(e.target.value)}
				borderRadius={'8px'}
				placeholder={t('search_tracks') ?? ''}
			/>

			<Text fontSize="2xl" fontWeight="medium">
				{t('tracks')}
			</Text>

			{filtered.map((journey) => (
				<TrackAccordion
					key={journey.id}
					hideStatus={true}
					journey={journey}
					renderStoryButton={(storyId) => (
						<ViewStoryButton storyId={storyId} journeyId={journey.id} />
					)}
				/>
			))}
		</BasePage>
	);
};
