import { SchemaSessionResponseDto } from '@jam/api-sdk/api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from './buildBaseQuery';

const api = createApi({
	reducerPath: 'learner-api',
	tagTypes: ['story-sessions'],
	baseQuery: buildBaseQuery('/api/v1'),
	endpoints: (builder) => ({
		getStoryLastSession: builder.query<
			SchemaSessionResponseDto | null,
			{ id: string; userId: string }
		>({
			query: ({ id, userId }) =>
				`sessions?storyReferenceIds[]=${id}&userIds[]=${userId}`,
			transformResponse: (response: SchemaSessionResponseDto[]) => {
				return response.length > 0 ? response[0] : null;
			},
		}),
		getStorySessions: builder.query<
			SchemaSessionResponseDto[],
			{ id: string; userId: string }
		>({
			providesTags: ['story-sessions'],
			query: ({ id, userId }) =>
				`sessions?storyReferenceIds[]=${id}&state=DONE&userIds[]=${userId}`,
		}),
		getSession: builder.query<SchemaSessionResponseDto, string>({
			query: (id: string) => `sessions/${id}`,
		}),
		getSessionsByUserId: builder.query<SchemaSessionResponseDto[], string>({
			query: (userId: string) => `sessions?userIds[]=${userId}&state=DONE`,
		}),
		createSession: builder.mutation<
			{ id: string; call: { assistantReferenceId: string } },
			{ storyId: string }
		>({
			query: (req) => ({
				url: 'sessions',
				method: 'POST',
				body: req,
			}),
		}),
		uploadTranscript: builder.mutation<
			SchemaSessionResponseDto,
			{ userId: string; storyId: string; transcript: string }
		>({
			query: (req) => ({
				url: `sessions/upload-transcript`,
				method: 'POST',
				body: req,
			}),
		}),
		deleteSession: builder.mutation<void, string>({
			query: (id: string) => ({
				url: `sessions/${id}`,
				method: 'DELETE',
			}),
		}),
		getUserTotalExperience: builder.query<number, void>({
			query: () => `experience/total`,
		}),
	}),
});

export const {
	useGetStorySessionsQuery,
	useLazyGetStorySessionsQuery,
	useCreateSessionMutation,
	useGetSessionsByUserIdQuery,
	useGetSessionQuery,
	useDeleteSessionMutation,
	useGetStoryLastSessionQuery,
	useLazyGetStoryLastSessionQuery,
	useGetUserTotalExperienceQuery,
	useLazyGetUserTotalExperienceQuery,
	useUploadTranscriptMutation,
} = api;
export default api;
