import { Stack, useDisclosure } from '@chakra-ui/react';
import {
	SchemaJourneyResponseDto,
	SchemaStoryResponseDto,
} from '@jam/api-sdk/api';
import { PencilSimple } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetStoryDetails } from '../../common/hooks/useGetStoryDetails';
import { usePageBreadcrumbs } from '../../common/hooks/usePageBreadcrumbs';
import { MissionDetail } from '../../features/manager-journeys/components/MissionDetail';
import { RequestEditsModal } from '../../features/manager-journeys/components/RequestEditsModal';
import { useGetJourneyQuery } from '../../redux/api/cmsApi';
import { BasePage } from '../common/BasePage';
import { Button } from '@jam/front-library';

const useManagerMissionDetailsPageBreadcrumbs = (
	journey?: SchemaJourneyResponseDto,
	mission?: SchemaStoryResponseDto
) => {
	return usePageBreadcrumbs(
		[
			{
				title: mission?.title ?? '',
				id: 'missionDetails',
				link: `/journey/${journey?.id ?? ''}/mission/${mission?.id ?? ''}`,
			},
		],
		!journey?.id || !mission?.id
	);
};
export const ManagerMissionDetailPage = () => {
	const { t } = useTranslation('common');
	const { journeyId, missionId } = useParams();
	const { data: journey } = useGetJourneyQuery(journeyId as string, {
		skip: !journeyId,
	});
	const mission = useGetStoryDetails(missionId);
	const { open: isOpen, onOpen, onClose } = useDisclosure();
	const steps = useManagerMissionDetailsPageBreadcrumbs(journey, mission);

	if (!journey || !mission) {
		return null;
	}

	return (
		<BasePage
			title={mission.title}
			titleActions={[
				<Stack maxWidth="380px" key={'requestEdits'}>
					<Button
						key={'requestEdits'}
						onClick={() => onOpen()}
						icon={<PencilSimple size={16} />}
						size="full"
					>
						{t('requestEdits')}
					</Button>
				</Stack>,
			]}
			breadcrumbsProps={{
				items: steps,
			}}
		>
			<MissionDetail />
			<RequestEditsModal isOpen={isOpen} onClose={onClose} />
		</BasePage>
	);
};
