import { Box, Flex } from '@chakra-ui/react';
import {
	JourneyResponseDTOStoriesAdditional_materialRelationTo,
	SchemaStoryResponseDto,
} from '@jam/api-sdk/api';
import {
	AccordionItem,
	AccordionItemContent,
	AccordionItemTrigger,
	AccordionRoot,
	Button,
	Text,
} from '@jam/front-library';
import { ArrowSquareOut, FileText, Folder } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MissionTaskList } from '../../../../common/components/MissionTaskList';

type MoreInfoTabProps = {
	story?: SchemaStoryResponseDto;
};

export const MoreInfoTab = ({ story }: MoreInfoTabProps) => {
	const { t } = useTranslation('common');

	if (!story) {
		return null;
	}

	const materials = story.additional_material?.map((material) => {
		return {
			value: material.value as Record<string, any>,
			relationTo: material.relationTo,
		};
	});

	return (
		<Flex w="100%" direction={'column'} gap="4">
			<Text fontSize={'24px'} fontWeight={'medium'}>
				{t('your_tasks')}
			</Text>
			<MissionTaskList tasks={story.scorecard.items} />
			{materials?.length > 0 && (
				<>
					<Text fontSize={'24px'} mt="8" fontWeight={'medium'}>
						{t('additional_material')}
					</Text>
					<Flex direction={'column'} gap="4">
						<AccordionRoot
							multiple
							defaultValue={materials.map((_, index) => index.toString())}
						>
							{materials.map((material, index) => {
								let icon = <FileText size={20} />;
								if (
									material.relationTo ===
									JourneyResponseDTOStoriesAdditional_materialRelationTo.client_documents
								) {
									icon = <Folder size={20} />;
								}
								if (
									material.relationTo ===
									JourneyResponseDTOStoriesAdditional_materialRelationTo.external_links
								) {
									icon = <ArrowSquareOut size={20} />;
								}
								return (
									<AccordionItem
										mb="5"
										key={index}
										w={'100%'}
										borderWidth={'1.5px'}
										p={6}
										borderRadius={'12px'}
										borderColor={'gray.300'}
										value={index.toString()}
									>
										<Box
											borderRadius={'24px'}
											border={'1px'}
											borderColor={'gray.300'}
											px="4"
											py="6"
										>
											<AccordionItemTrigger _hover={{ bg: 'transparent' }}>
												<Flex
													alignItems={'center'}
													w="100%"
													justifyContent={'space-between'}
												>
													<Flex gap="2" alignItems={'center'}>
														<Box
															border={'1px'}
															p="2"
															borderRadius={'24px'}
															borderColor={'gray.300'}
														>
															{icon}
														</Box>
														<Text fontSize={'16px'} fontWeight={'medium'}>
															{material.value.title}
														</Text>
													</Flex>

													{material.relationTo ===
													JourneyResponseDTOStoriesAdditional_materialRelationTo.text_snippets ? (
														<></>
													) : (
														<Link
															to={
																material.relationTo ===
																JourneyResponseDTOStoriesAdditional_materialRelationTo.external_links
																	? (material.value.url as string)
																	: (material.value.signedUrl as string)
															}
															onClick={(e) => {
																e.stopPropagation();
															}}
															target="_blank"
														>
															<Button variant="secondary">
																{material.relationTo ===
																JourneyResponseDTOStoriesAdditional_materialRelationTo.external_links
																	? t('open_link')
																	: t('download_pdf')}
															</Button>
														</Link>
													)}
												</Flex>
											</AccordionItemTrigger>
											<AccordionItemContent py="5">
												<Text fontSize={'14px'} color={'#757575'}>
													{material.value.description}
												</Text>
											</AccordionItemContent>
										</Box>
									</AccordionItem>
								);
							})}
						</AccordionRoot>
					</Flex>
				</>
			)}
		</Flex>
	);
};
