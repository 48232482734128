import { Box, Flex, Skeleton, useDisclosure } from '@chakra-ui/react';
import { SchemaTeamResponseDto, SchemaUserOverviewDto } from '@jam/api-sdk/api';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CreatedAtCell } from '../../../../../common/components/custom-table/cells/common/CreatedAtCell';
import { UserAvatarCell } from '../../../../../common/components/custom-table/cells/user/UserAvatarCell';
import { UserRoleCell } from '../../../../../common/components/custom-table/cells/user/UserRoleCell';
import { UserTeamCell } from '../../../../../common/components/custom-table/cells/user/UserTeamCell';
import { TeamCreateModal } from './TeamCreateModal';
import { useRegisteredMembersTable } from './useRegisteredMembersTable';
import { Plus } from '@phosphor-icons/react';
import { Button, Table } from '@jam/front-library';

const LoadingState = () => {
	return (
		<Flex role="placeholder" direction={'column'} gap="4">
			<Box maxW="300px">
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
			</Box>
			<Box display={'flex'} flexDirection={'column'} h="200px" w="100%" gap={2}>
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
			</Box>
		</Flex>
	);
};

interface Props {
	teams: SchemaTeamResponseDto[];
}

export const RegisteredMembers: React.FC<Props> = ({ teams }) => {
	const { t } = useTranslation(['common', 'team']);
	const navigate = useNavigate();
	const { open: isOpen, onOpen, onClose } = useDisclosure();

	const { isLoading, data, onRoleChange, onTeamChange, onCreateTeam } =
		useRegisteredMembersTable();

	const columnHelper = createColumnHelper<SchemaUserOverviewDto>();

	// Components defined in a separate file -> make sure this file stays small and readable
	const columns = [
		columnHelper.accessor('email', {
			header: t('common:user').toString(),
			cell: ({ row }) => <UserAvatarCell row={row.original} />,
			meta: {
				name: 'email',
				filtering: {
					variant: 'text',
					placeholder: t('team:member_search_placeholder') ?? '',
				},
			},
		}),
		columnHelper.accessor('role', {
			header: t('common:role').toString(),
			cell: ({ row }) => (
				<UserRoleCell row={row.original} onRoleChange={onRoleChange} />
			),
		}),
		// Always first team id
		columnHelper.accessor((row) => row.teams[0].id, {
			id: 'teams',
			header: t('team:team').toString(),
			cell: ({ row }) => (
				<UserTeamCell
					teams={teams}
					row={row.original}
					onTeamChange={onTeamChange}
				/>
			),
			meta: {
				name: 'teams',
				filtering: {
					variant: 'select',
					placeholder: t('team:all_teams').toString(),
					options:
						teams?.map((team) => ({
							value: team.id,
							label: team.name,
						})) ?? [],
				},
			},
		}),
		columnHelper.accessor('createdAt', {
			header: t('team:member_since').toString(),
			cell: ({ row }) => <CreatedAtCell row={row.original} />,
		}),
	];

	if (isLoading) return <LoadingState />;

	return (
		<Flex gap={6} direction={'column'}>
			<TeamCreateModal
				isOpen={isOpen}
				onClose={onClose}
				onSubmit={onCreateTeam}
			/>
			<Table<SchemaUserOverviewDto>
				enablePagination
				data={data ?? []}
				columns={columns as unknown as ColumnDef<SchemaUserOverviewDto, any>[]}
				onRowClick={(item) => {
					navigate(`/dashboard/user/${item.id}`);
				}}
				actions={[
					<Button
						key={'add-team-button'}
						id="add-team-button"
						type="submit"
						onClick={onOpen}
						icon={<Plus size={15} />}
					>
						{t('team:add_team')}
					</Button>,
				]}
			/>
		</Flex>
	);
};
