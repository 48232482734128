import { Box, Flex, Skeleton } from '@chakra-ui/react';
import { Button, SkeletonText, Text } from '@jam/front-library';
import { CaretRight } from '@phosphor-icons/react';
import { useAuthInfo } from '@propelauth/react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { CallBar } from '../../../common/components/CallBar';
import { useGetFirstNotCompletedStory } from '../../../common/utils/getfirstNotCompletedStory';

const LoadingState = () => {
	return (
		<Flex role="placeholder" direction={'column'} gap="3">
			<Box maxW="300px">
				<SkeletonText noOfLines={1} height={'30px'} />
			</Box>
			<Flex gap="4" alignItems={'center'}>
				<Skeleton borderRadius={'8px'} h="175px" w="175px" />
				<SkeletonText w={'full'} noOfLines={5} gap={'5'} />
			</Flex>
		</Flex>
	);
};

export const NextStory = () => {
	const { user } = useAuthInfo();
	const { t } = useTranslation(['home', 'story']);
	const navigate: NavigateFunction = useNavigate();
	const { activeStory, isLoading: isLoadingActiveStory } =
		useGetFirstNotCompletedStory();

	const renderStoryButton = (storyId: string) => {
		const buttonText = t('start');

		return (
			<Button
				id="next-story-start-button"
				onClick={() => navigate(`/call?storyId=${storyId}`)}
				icon={<CaretRight size="12px" />}
				iconPosition={'end'}
			>
				{buttonText}
			</Button>
		);
	};

	if (isLoadingActiveStory) {
		return <LoadingState />;
	}

	if (activeStory === null || !user) {
		return null;
	}

	return (
		<Box>
			<Flex mb="3" alignItems={'center'} gap="2">
				<Text w="fit-content" fontSize="20px" fontWeight="500">
					{t('your_next_mission')}
				</Text>
			</Flex>
			<CallBar
				story={activeStory}
				dueDate={activeStory.dueDate}
				userId={user.userId}
				renderStoryButton={renderStoryButton}
			/>
		</Box>
	);
};
