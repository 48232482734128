import { PathsApiV1UsersGetParametersQueryRole } from '@jam/api-sdk/api';
import { Select } from '@jam/front-library';
import React from 'react';

export const UserRoleCell: React.FC<{
	row: {
		id?: string;
		role?: PathsApiV1UsersGetParametersQueryRole;
	};
	onRoleChange?: (
		userId: string,
		role: PathsApiV1UsersGetParametersQueryRole
	) => Promise<void>;
}> = ({ row, onRoleChange }) => {
	const supportsClick = !!onRoleChange;
	return (
		<Select
			borderRadius={'10px'}
			borderColor={'black'}
			// size={'sm'}
			disabled={!supportsClick}
			// maxW="100px"
			value={row.role}
			cursor={supportsClick ? 'pointer' : 'not-allowed'}
			onValueChange={(value) => {
				if (!supportsClick || !row.id) return;
				void onRoleChange(
					row.id,
					value as PathsApiV1UsersGetParametersQueryRole
				);
			}}
			onClick={(e) => {
				if (!supportsClick) return;
				// Prevent the row click event from firing
				e.stopPropagation();
			}}
			items={[
				{ value: 'Member', label: 'Member' },
				{ value: 'Manager', label: 'Manager' },
				{ value: 'Owner', label: 'Owner' },
			]}
		/>
	);
};
