// eslint-disable-next-line import/no-named-as-default
import i18n, { changeLanguage, use } from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import store from '../redux/store';

// eslint-disable-next-line @typescript-eslint/no-floating-promises
use(Backend)
	.use(initReactI18next)
	.init({
		fallbackLng: 'de',
		ns: [
			'home',
			'call',
			'gym',
			'history',
			'common',
			'story',
			'settings',
			'dashboard',
			'real-call',
			'course-catalog',
		],
		defaultNS: 'home',
		debug: false,
		keySeparator: '.',
		interpolation: {
			escapeValue: false,
		},
	});

store.subscribe(() => {
	const newLang = store.getState().applicationState.language ?? 'de';
	if (i18n.language !== newLang) {
		void changeLanguage(newLang);
	}
});

export default i18n;
