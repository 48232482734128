import { Chip } from '@jam/front-library';
import { useTranslation } from 'react-i18next';
import { StoryStatus } from '../../hooks/useStoryStatus';

export const StoryCardImageHeader = ({
	status,
	hideStatus,
}: {
	status?: StoryStatus;
	hideStatus?: boolean;
}) => {
	const { t } = useTranslation(['story', 'history']);

	if (!status || hideStatus || status !== StoryStatus.COMPLETED) {
		return null;
	}

	return (
		<Chip
			h="24px"
			bg="#EAFDEE"
			color="#147129"
			borderRadius={'24px'}
			border={0}
			fontSize={'14px'}
			gap={'4px'}
			fontWeight={'medium'}
			text={t(status)}
		/>
	);
};
