import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StoryCardButton } from '../StoryCardButton';

export interface StartOrResumeStoryButtonProps {
	storyId: string;
}

export const StartOrResumeStoryButton = ({
	storyId,
}: StartOrResumeStoryButtonProps) => {
	const { t } = useTranslation('story');
	const navigate = useNavigate();

	const label = 'start_mission';

	return (
		<StoryCardButton
			label={t(label)}
			onClick={() => {
				navigate(`/call?storyId=${storyId}`);
			}}
		/>
	);
};
