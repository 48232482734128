import { Box, Flex, Input, Stack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { CreateLearnerStoryItemFeedbackRequestDTOLearnerStoryItemPresets } from '@jam/api-sdk/api';
import {
	Button,
	DialogBackdrop,
	DialogBody,
	DialogCloseTrigger,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogRoot,
	DialogTitle,
	Field,
	Text,
} from '@jam/front-library';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCreateItemUserFeedbackMutation } from '../../../redux/api/userFeedbackApi';
import {
	NegativeFeedbackPerItem,
	NegativeFeedbackPerItemSchema,
} from '../types/FormTypes';

const FEEDBACK_ELEMENTS: Record<
	CreateLearnerStoryItemFeedbackRequestDTOLearnerStoryItemPresets,
	string
> = {
	NOT_FACTUALLY_CORRECT: 'not_factually_correct',
	DONT_UNDERSTAND: 'dont_understand',
	GRAMMAR_MISTAKE_OR_SPELLING_ERROR: 'grammar_mistake_or_spelling_error',
	TOO_COMPLICATED: 'too_complicated',
};

type NegativeUserFeedbackModalProps = {
	itemId: string;
	sessionId: string;
	isOpen: boolean;
	onClose: () => void;
};

const langProps: Record<
	CreateLearnerStoryItemFeedbackRequestDTOLearnerStoryItemPresets,
	string
> = {
	NOT_FACTUALLY_CORRECT: 'call:negative_feedback_modal.not_factually_correct',
	DONT_UNDERSTAND: 'call:negative_feedback_modal.dont_understand',
	GRAMMAR_MISTAKE_OR_SPELLING_ERROR:
		'call:negative_feedback_modal.grammar_mistake_or_spelling_error',
	TOO_COMPLICATED: 'call:negative_feedback_modal.too_complicated',
};

export const NegativeUserFeedbackModal = ({
	isOpen,
	sessionId,
	onClose,
	itemId,
}: NegativeUserFeedbackModalProps) => {
	const { t } = useTranslation(['call', 'common']);
	const [addUserItemFeedback, { isLoading }] =
		useCreateItemUserFeedbackMutation();
	const { register, handleSubmit, getValues, setValue, watch } =
		useForm<NegativeFeedbackPerItem>({
			resolver: zodResolver(NegativeFeedbackPerItemSchema),
			defaultValues: {
				reasons: [],
				feedback: '',
			},
		});

	const onItemClicked = (reason: string) => {
		const reasons = getValues('reasons');
		if (reasons.includes(reason)) {
			setValue(
				'reasons',
				reasons.filter((r) => r !== reason)
			);
		} else {
			setValue('reasons', [...reasons, reason]);
		}
	};

	const onReset = () => {
		setValue('feedback', '');
		setValue('reasons', []);
	};

	const onSubmit = async (data: NegativeFeedbackPerItem) => {
		await addUserItemFeedback({
			storyItemReferenceId: itemId,
			sessionId,
			positive: false,
			content: data.feedback,
			learnerStoryItemPresets: data.reasons,
		});
		onClose();
		onReset();
	};
	const reasons = watch('reasons');
	const feedback = watch('feedback');
	return (
		<DialogRoot
			size={'lg'}
			open={isOpen}
			onOpenChange={({ open }) => {
				if (!open) {
					onClose();
				}
			}}
		>
			<DialogBackdrop />
			<DialogContent>
				<DialogHeader>
					<DialogTitle fontSize={'16px'}>
						{t('negative_feedback_modal.title')}
					</DialogTitle>
				</DialogHeader>
				<DialogCloseTrigger />
				<DialogBody pb={6}>
					<Stack gap={6}>
						<Flex flexWrap={'wrap'} gap="3">
							{Object.entries(FEEDBACK_ELEMENTS).map((element) => (
								<Box
									onClick={() => onItemClicked(element[0])}
									key={element[0]}
									cursor={'pointer'}
									color={reasons.includes(element[0]) ? '#4241E4' : '#757575'}
									border={'1px solid'}
									borderColor={
										reasons.includes(element[0]) ? '#4241E4' : '#D9D9D9'
									}
									px="2"
									bg={
										reasons.includes(element[0])
											? 'rgba(66, 65, 228, 1, 0.08)'
											: 'transparent'
									}
									borderRadius={'24px'}
								>
									<Text fontSize={'12px'}>
										{t(
											langProps[
												element[0] as CreateLearnerStoryItemFeedbackRequestDTOLearnerStoryItemPresets
											]
										)}
									</Text>
								</Box>
							))}
						</Flex>

						<Field as="fieldset">
							<Input
								{...register('feedback')}
								placeholder={t('negative_feedback_modal.placeholder') ?? ''}
								size="sm"
							/>
						</Field>
					</Stack>
				</DialogBody>
				<DialogFooter>
					<Button
						id="submit-negative-feedback-button"
						type="submit"
						loading={isLoading}
						disabled={feedback.length === 0}
						onClick={() => {
							if (feedback.length > 0) void handleSubmit(onSubmit)();
						}}
					>
						{t('common:submit')}
					</Button>
				</DialogFooter>
			</DialogContent>
		</DialogRoot>
	);
};
