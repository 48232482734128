import { Box, Flex, Input, List, Stack, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Field } from '@jam/front-library';
import { useLogoutFunction } from '@propelauth/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z, ZodType } from 'zod';
import { useChangePasswordMutation } from '../../../redux/api/crmApi';

type NewPasswordForm = {
	password: string;
	confirmPassword: string;
};

const NewPasswordSchema: ZodType<NewPasswordForm> = z
	.object({
		password: z
			.string()
			.min(8, 'Password must be at least 8 characters long')
			.regex(/[a-z]/, 'Password must contain at least one lowercase letter')
			.regex(/[A-Z]/, 'Password must contain at least one uppercase letter')
			.regex(/[0-9]/, 'Password must contain at least one number'),
		confirmPassword: z.string(),
	})
	.refine((data) => data.password === data.confirmPassword, {
		message: "Passwords don't match",
		path: ['confirmPassword'], // path of error
	});

export const PasswordChangeForm = () => {
	const logout = useLogoutFunction();
	const { t } = useTranslation('settings');
	const [changePassword] = useChangePasswordMutation();
	const {
		register,
		handleSubmit,
		setValue,
		formState: { isDirty, errors, isSubmitting },
	} = useForm<NewPasswordForm>({
		resolver: zodResolver(NewPasswordSchema),
	});

	const onReset = () => {
		setValue('password', '');
		setValue('confirmPassword', '');
	};

	const onSubmit = async (data: NewPasswordForm) => {
		await changePassword({
			password: data.password,
		});
		await logout(true);
	};

	return (
		<Flex direction={'column'}>
			<Text fontWeight={'medium'} fontSize={'24px'} color={'#757575'}>
				{t('change-password')}
			</Text>
			<Stack mt="8" gap={4} maxW={'500px'}>
				<Field
					invalid={!!errors.password}
					label={t('new-password')}
					errorText={errors.password?.message}
				>
					<Input
						type={'password'}
						borderRadius={'full'}
						{...register('password')}
					/>
				</Field>
				<Field
					invalid={!!errors.confirmPassword}
					label={t('confirm-password')}
					errorText={errors.confirmPassword?.message}
				>
					<Input
						type={'password'}
						borderRadius={'full'}
						{...register('confirmPassword')}
					/>
				</Field>
				<Box mt="2">
					<Text my="2" fontSize={'12px'} color={'#757575'}>
						{t('password-conditions.title')}
					</Text>
					<List.Root fontSize={'12px'} color="#757575" as={'ul'}>
						<List.Item>{t('password-conditions.uppercase')}</List.Item>
						<List.Item>{t('password-conditions.lowercase')}</List.Item>
						<List.Item>{t('password-conditions.number')}</List.Item>
						<List.Item>{t('password-conditions.length')}</List.Item>
					</List.Root>
					<Text mt="2" fontSize={'12px'} color={'#757575'} fontStyle={'italic'}>
						{t('password-conditions.note')}
					</Text>
				</Box>
				<Flex mt="4" gap="5">
					<Button
						id="change-password-button"
						type="submit"
						loading={isSubmitting}
						disabled={!isDirty}
						size="sm"
						onClick={() => {
							if (!isDirty) return;
							void handleSubmit(onSubmit)();
						}}
					>
						{t('save')}
					</Button>
					<Button
						variant="tertiary"
						type="reset"
						size="sm"
						onClick={() => {
							onReset();
						}}
					>
						{t('cancel')}
					</Button>
				</Flex>
			</Stack>
		</Flex>
	);
};
