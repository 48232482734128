import { Box, Flex, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { Button, SkeletonText, Table } from '@jam/front-library';
import { Plus } from '@phosphor-icons/react';
import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetOrganizationUsersQuery } from '../../../redux/api/crmApi';
import { AssignContentModal } from './AssignContentModal';

const LoadingState = () => {
	return (
		<Flex role="placeholder" mt="5" direction={'column'} gap="6">
			<Box maxW="300px">
				<SkeletonText noOfLines={1} height={'30px'} />
			</Box>
			<SkeletonText w={'full'} noOfLines={5} gap={'5'} />
		</Flex>
	);
};

type SelectableUser = {
	id: string;
	select: boolean;
	email: string;
};

export const UsersSelectionTable = () => {
	const columnHelper = createColumnHelper<SelectableUser>();

	const { t } = useTranslation(['settings', 'common']);
	const { open: isOpen, onOpen, onClose } = useDisclosure();
	const { data, isLoading } = useGetOrganizationUsersQuery();
	const [rowSelection, setRowSelection] = useState({});
	const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
	const [columns] = useState([
		columnHelper.accessor('email', {
			header: t('common:email').toString(),
			cell: ({ row }) => (
				<Text fontWeight={'medium'}>{row.original.email}</Text>
			),
			meta: {
				name: 'email',
				filtering: {
					variant: 'text',
					placeholder: t('search-by-email') ?? '',
				},
			},
		}),
	]);
	const [userData, setUserData] = useState<SelectableUser[]>([]);

	useEffect(() => {
		setUserData(
			(data ?? [])
				.map((user) => ({
					id: user.id,
					email: user.email,
				}))
				.filter((user): user is { id: string; email: string } => !!user.email)
				.map((user) => ({
					id: user.id,
					select: false,
					email: user.email,
				}))
		);
	}, [data]);

	useEffect(() => {
		const ids = Object.keys(rowSelection)
			.map((key) => parseInt(key))
			.map((index) => userData[index]?.id)
			.filter((id) => !!id);

		setSelectedUserIds(ids);
	}, [rowSelection, userData]);

	if (isLoading) {
		return <LoadingState />;
	}

	return (
		<Stack direction="column" gap="5">
			<Table<SelectableUser>
				data={userData}
				columns={columns}
				enablePagination
				enableSelection
				onRowSelectionChange={setRowSelection}
				actions={[
					<Button
						key={'add-user-button'}
						id="add-user-button"
						type="submit"
						disabled={Object.keys(rowSelection).length === 0}
						onClick={onOpen}
						icon={<Plus size={15} />}
					>
						{t('assign-content')}
					</Button>,
				]}
			/>
			<AssignContentModal
				userIds={selectedUserIds}
				isOpen={isOpen}
				onClose={onClose}
			/>
		</Stack>
	);
};
