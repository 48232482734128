import {
	PathsApiV1AssignmentsGetParametersQueryReferenceType,
	SchemaKeyStoryForImprovementDto,
} from '@jam/api-sdk/api';
import { toaster } from '@jam/front-library';
import { PushPin } from '@phosphor-icons/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { StoryCard } from '../../../../../../common/components/story-detail/StoryCard';
import { StoryCardButton } from '../../../../../../common/components/story-detail/StoryCardButton';
import { useGetStoryDetails } from '../../../../../../common/hooks/useGetStoryDetails';
import { useAssignContentMutation } from '../../../../../../redux/api/contentAllocationApi';

interface KeyImprovementStoryCardProps {
	userId: string;
	story: SchemaKeyStoryForImprovementDto;
	assigned: boolean;
}

export const KeyImprovementStoryCard: React.FC<
	KeyImprovementStoryCardProps
> = ({ userId, story, assigned }: KeyImprovementStoryCardProps) => {
	const { t } = useTranslation(['dashboard', 'story']);
	const storyDetails = useGetStoryDetails(story.storyReferenceId);
	const [assignContentToUser] = useAssignContentMutation();
	const location = useLocation();
	const navigate = useNavigate();

	const onAssignContent = async (): Promise<void> => {
		if (!userId) return;

		await assignContentToUser({
			userIds: [userId],
			reference: {
				id: story.storyReferenceId,
				type: PathsApiV1AssignmentsGetParametersQueryReferenceType.story,
			},
		});

		toaster.create({
			title: t('story:content_assigned_title'),
			description: t('story:content_assigned_description'),
			type: 'success',
		});
	};

	const onStartMission = () => {
		navigate(`/call?storyId=${story.storyReferenceId}`);
	};

	if (!storyDetails) return null;

	return (
		<StoryCard
			story={storyDetails}
			renderStoryButton={() => {
				if (location.pathname.includes('progress')) {
					return (
						<StoryCardButton
							label={t('story:start_mission')}
							onClick={onStartMission}
						/>
					);
				} else {
					return (
						<StoryCardButton
							label={
								assigned
									? t('story:mission_assigned')
									: t('story:assign_mission')
							}
							onClick={() => {
								onAssignContent().catch(() => {});
							}}
							disabled={assigned}
							icon={<PushPin />}
						/>
					);
				}
			}}
		/>
	);
};
