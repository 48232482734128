import { Flex, Stack } from '@chakra-ui/react';
import { Avatar, Text } from '@jam/front-library';
import React from 'react';
import { getUserDisplayName } from '../../../../utils/stringUtil';

export const UserAvatarCell: React.FC<{
	row: {
		firstName?: string | null;
		lastName?: string | null;
		pictureUrl?: string | null;
		email?: string | null;
	};
}> = ({ row }) => {
	return (
		<Flex alignItems={'center'} gap="12px" width={'280px'}>
			<Avatar
				bg="gray"
				size="sm"
				name={getUserDisplayName(row.firstName, row.lastName, 'N/A')}
				src={row.pictureUrl ?? ''}
			/>

			<Stack direction="column" alignItems="start" gap={'4px'}>
				<Text>
					{getUserDisplayName(row.firstName, row.lastName, 'Unknown User')}
				</Text>
				<Text fontSize={'13px'} color={'#757575'}>
					{row.email}
				</Text>
			</Stack>
		</Flex>
	);
};
