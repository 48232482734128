import { Box, Flex, IconButton, Stack } from '@chakra-ui/react';
import { Text } from '@jam/front-library';
import { CaretLeft, IconProps } from '@phosphor-icons/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { BreadCrumbItem } from '../../../../providers/breadcrumbs/NavigationBreadcrumbsProvider';

export type BreadCrumbProps = {
	items: BreadCrumbItem[];
	onBackClick?: () => void;
	showItems?: boolean;
	icon?: React.ForwardRefExoticComponent<
		IconProps & React.RefAttributes<SVGSVGElement>
	>;
};

export const BreadCrumbs = ({
	showItems = true,
	items,
	onBackClick,
	icon,
}: BreadCrumbProps) => {
	const IconComponent = icon ?? CaretLeft;
	return (
		<Box w="100%" mb="5" borderRadius={'md'} role="heading">
			<Flex
				direction={'row'}
				gap={10}
				justifyContent={'space-between'}
				alignItems={{ base: 'column', lg: 'center' }}
				w="100%"
			>
				<Flex alignItems={'center'} gap={3}>
					{onBackClick && (
						<IconButton
							width={'fit-content'}
							w={'fit-content'}
							px={0}
							size={'xs'}
							bg="#EDF2F7"
							color={'#0B1215'}
							_hover={{ bg: 'gray.200' }}
							aria-label="Back"
							onClick={onBackClick}
						>
							<IconComponent size={20} />
						</IconButton>
					)}
					{showItems && (
						<Stack direction="row" gap={3}>
							{items?.map((item, index) => {
								return (
									<>
										{item.link ? (
											<Link to={item.link || '#'} key={index}>
												<Text
													color={
														items.length === index + 1 ? 'black' : '#757575'
													}
													variant="label"
												>
													{item.title}
												</Text>
											</Link>
										) : (
											<Text
												key={index}
												color={items.length === index + 1 ? 'black' : '#757575'}
												variant="label"
											>
												{item.title}
											</Text>
										)}
										{index !== items.length - 1 && (
											<Text variant="label">/</Text>
										)}
									</>
								);
							})}
						</Stack>
					)}
				</Flex>
			</Flex>
		</Box>
	);
};
