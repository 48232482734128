import { Box, Flex, Popover } from '@chakra-ui/react';
import { SessionResponseDTOAnalysisItemsSolved } from '@jam/api-sdk/api';

export const SolvedStatusToColorMap: Record<
	SessionResponseDTOAnalysisItemsSolved,
	string
> = {
	[SessionResponseDTOAnalysisItemsSolved.solved]: '#73F5A2',
	[SessionResponseDTOAnalysisItemsSolved.partlySolved]: '#F8DE00',
	[SessionResponseDTOAnalysisItemsSolved.notSolved]: '#FF6767',
	[SessionResponseDTOAnalysisItemsSolved.undetected]: 'gray.500',
};

export const SolvedItemDots = ({
	showMax = 3,
	items,
}: {
	showMax?: number;
	items: (SessionResponseDTOAnalysisItemsSolved | undefined)[];
}) => {
	const results = items.map(
		(x) => x ?? SessionResponseDTOAnalysisItemsSolved.undetected
	);

	const areMany = results.length > showMax;
	const showResults = areMany ? results.slice(0, showMax - 1) : results;

	return (
		<Popover.Root positioning={{ placement: 'top' }}>
			<Popover.Trigger>
				<Flex alignItems={'center'} w="fit-content">
					{showResults.map((result, index) => {
						return (
							<Box
								aria-label="item-status-trigger"
								ml="-2"
								w="22px"
								h="22px"
								bg={SolvedStatusToColorMap[result]}
								borderRadius={'full'}
								borderWidth={2}
								borderColor={'white'}
								key={index}
							/>
						);
					})}
					{areMany && (
						<Box
							aria-label="more-item-status"
							ml="-2"
							bg={'black'}
							px="1"
							borderRadius={'full'}
							fontSize={'11px'}
							color={'white'}
							borderWidth={2}
							borderColor={'white'}
						>
							+{results.length - showMax + 1}
						</Box>
					)}
				</Flex>
			</Popover.Trigger>
			<Popover.Content px="4" py="2" w="fit-content">
				<Flex alignItems={'center'}>
					{results.map((result, index) => {
						return (
							<Box
								aria-label="item-status"
								ml="-2"
								w="22px"
								h="22px"
								bg={SolvedStatusToColorMap[result]}
								borderRadius={'full'}
								borderWidth={2}
								borderColor={'white'}
								key={index}
							/>
						);
					})}
				</Flex>
			</Popover.Content>
		</Popover.Root>
	);
};
