import {
	Button,
	MenuContent,
	MenuItem,
	MenuRoot,
	MenuTrigger,
} from '@jam/front-library';
import { DotsThreeVertical } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface Props {
	missionId: string;
	trackId: string;
}

export const MissionDetailDots: React.FC<Props> = ({
	missionId,
	trackId,
}: Props) => {
	const { t } = useTranslation('performance');
	const navigate = useNavigate();

	const handleSeeMission = () => {
		navigate(`/journey/${trackId}/mission/${missionId}`);
	};

	const handleTestMission = () => {
		navigate(`/call?storyId=${missionId}`);
	};

	return (
		<MenuRoot>
			<MenuTrigger>
				<Button
					variant="tertiary"
					icon={<DotsThreeVertical />}
					data-testid="trigger-button"
				/>
			</MenuTrigger>
			<MenuContent>
				<MenuItem value={'see_mission'} onClick={handleSeeMission}>
					{t('performance_by_mission.see_mission')}
				</MenuItem>
				<MenuItem value={'test_mission'} onClick={handleTestMission}>
					{t('performance_by_mission.test_mission')}
				</MenuItem>
			</MenuContent>
		</MenuRoot>
	);
};
