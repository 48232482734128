import { SchemaTeamResponseDto } from '@jam/api-sdk/api';
import { Select } from '@jam/front-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const UserTeamCell: React.FC<{
	teams: SchemaTeamResponseDto[];
	row: {
		id: string;
		teams?: { id: string }[];
	};
	onTeamChange?: (data: {
		userId: string;
		currentTeamId?: string;
		newTeamId?: string;
	}) => Promise<void>;
}> = ({ teams, row, onTeamChange }) => {
	const { t } = useTranslation('common');
	const supportsClick = !!onTeamChange;
	const firstTeam = row.teams?.[0];

	if (!teams.length) {
		return null;
	}

	return (
		<Select
			borderRadius={'10px'}
			borderColor={'black'}
			size={'sm'}
			disabled={false}
			value={firstTeam?.id}
			cursor={supportsClick ? 'pointer' : 'not-allowed'}
			onValueChange={(value) => {
				if (!supportsClick || !row.id) return;
				void onTeamChange({
					userId: row.id,
					currentTeamId: firstTeam?.id,
					newTeamId: value ? (value as string) : undefined,
				});
			}}
			onClick={(e) => {
				if (!supportsClick) return;
				// Prevent the row click event from firing
				e.stopPropagation();
			}}
			items={[
				{ value: '', label: t('none') ?? '' },
				...teams.map((team) => ({
					value: team.id,
					label: team.name,
				})),
			]}
		/>
	);
};
