import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../redux/hooks';
import {
	selectMenuIsOpen,
	setReportIssueModalIsOpen,
	toggleMenu,
} from '../../../redux/slice';
import { ExpandableSidebar } from './ExpandableSideBar';
import { Logo } from '@jam/front-library';
import { SidebarData, useAppSidebarData } from '../../hooks/useAppSidebarData';
import { SideBarNavigation } from './SideBarNavigation';
import { sidebarEntries } from '../../config/sidebarEntries';
import { ProfileButton } from './ProfileButton';

const presetOrder = {
	default: ['learner', 'manager', 'shared', 'help'],
	manager: ['manager', 'learner', 'shared', 'help'],
};

export const Sidebar = () => {
	const isOpen = useAppSelector(selectMenuIsOpen);
	const dispatch = useDispatch();
	const sidebarData = useAppSidebarData();

	const onToggle = () => {
		dispatch(toggleMenu());
	};

	const onReportIssue = () => {
		dispatch(setReportIssueModalIsOpen(true));
	};

	const order = sidebarData.isOwnerOrManager ? 'manager' : 'default';

	return (
		<ExpandableSidebar isOpen={isOpen} onToggle={onToggle}>
			<Logo size={isOpen ? 'full' : 'icon'} />
			<SideBarNavigation<SidebarData>
				isOpen={isOpen}
				data={sidebarData}
				items={sidebarEntries}
				events={{ onReportIssue }}
				order={presetOrder[order]}
				footer={<ProfileButton isOpen={isOpen} />}
			/>
		</ExpandableSidebar>
	);
};
