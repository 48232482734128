import { Box, Flex, IconButton } from '@chakra-ui/react';
import { Text, Tooltip } from '@jam/front-library';
import { CaretLeft, X } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { UserWidgets } from '../../../common/components/UserWidgets';
import { useGetUserById } from '../../../common/hooks/useGetUserById';
import { useGoBack } from '../../../common/hooks/useGoBack';

type CallHeaderProps = {
	userId?: string;
};

export const CallHeader = ({ userId }: CallHeaderProps) => {
	const { t } = useTranslation('call');
	const goBack = useGoBack();
	const user = useGetUserById(userId);

	const onCloseClick = () => {
		goBack();
	};

	return (
		<Box w="100%" px="10">
			<Flex gap={4} justifyContent={'space-between'} w="100%">
				<Flex gap={2} align={'center'}>
					<Tooltip
						disabled={!!userId}
						showArrow
						positioning={{ placement: 'right' }}
						content="Close call"
						aria-label="Close call"
					>
						<IconButton
							width={'fit-content'}
							size={'xs'}
							bg="#EDF2F7"
							color={'#0B1215'}
							_hover={{ bg: 'gray.200' }}
							aria-label="Back"
							onClick={onCloseClick}
						>
							{!userId ? <X size={20} /> : <CaretLeft size={20} />}
						</IconButton>
					</Tooltip>
					{userId && (
						<Flex gap={2} align={'center'}>
							<Text fontSize={'14px'} color={'#757575'}>
								{t('team')}
							</Text>
							<Text fontSize={'14px'} color={'#757575'}>
								/
							</Text>
							<Text fontSize={'14px'} color={'#0B1215'}>
								{user?.firstName} {user?.lastName}
							</Text>
						</Flex>
					)}
				</Flex>
				<UserWidgets />
			</Flex>
		</Box>
	);
};
