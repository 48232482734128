import { Flex } from '@chakra-ui/react';
import {
	TabsContent,
	TabsIndicator,
	TabsList,
	TabsRoot,
	TabsTrigger,
} from '@jam/front-library';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useActiveTab } from '../../../../common/hooks/useActiveTab';
import { HistoryTable } from '../../../user-history/components/HistoryTable';
import { UserDetailsOverview } from './overview/UserDetailsOverview';
import { UserPerformanceChart } from './performance/UserPerformanceChart';
import { UserPerformanceTable } from './performance/UserPerformanceTable';
import { CompletedAssignments } from './progress/CompletedAssignments';
import { CurrentAssignments } from './progress/CurrentAssignments';

interface UserDetailsProps {
	userId?: string;
	userName?: string;
	renderStoryCardButton: (storyId: string) => ReactElement;
}
export const UserDetails = ({
	userId,
	userName,
	renderStoryCardButton,
}: UserDetailsProps) => {
	const { t } = useTranslation(['dashboard', 'common']);
	const { activeTab, setActiveTab } = useActiveTab();
	const navigate = useNavigate();

	return (
		<TabsRoot
			lazyMount
			value={activeTab === null ? 'overview' : `tab-${activeTab}`}
			onValueChange={({ value }) => {
				const index =
					value === 'overview' ? null : parseInt(value.split('-')[1]);
				setActiveTab(index, 'replaceIn');
			}}
			variant="line"
		>
			<TabsList>
				<TabsTrigger value="overview">
					{t('dashboard:user_details.overview')}
				</TabsTrigger>
				<TabsTrigger value="tab-1">
					{t('dashboard:user_details.todo')}
				</TabsTrigger>
				<TabsTrigger value="tab-2">
					{t('dashboard:user_details.achievements')}
				</TabsTrigger>
				<TabsTrigger value="tab-3">
					{t('dashboard:user_details.role_play_history')}
				</TabsTrigger>
				<TabsTrigger value="tab-4">
					{t('dashboard:user_details.performance')}
				</TabsTrigger>
				<TabsIndicator />
			</TabsList>
			<TabsContent value="overview" p="0" py="8">
				<UserDetailsOverview userId={userId} />
			</TabsContent>
			<TabsContent value="tab-1" p="0">
				<CurrentAssignments
					userId={userId}
					renderStoryCardButton={renderStoryCardButton}
				/>
			</TabsContent>
			<TabsContent value="tab-2" p="0">
				<CompletedAssignments
					userId={userId}
					renderStoryCardButton={renderStoryCardButton}
				/>
			</TabsContent>
			<TabsContent value="tab-3" p="0">
				<HistoryTable
					userId={userId}
					onRowClick={(row) => {
						navigate(
							`/dashboard/user/${userId ?? ''}/session/${row.sessionId}?activeCallTabIndex=2`
						);
					}}
				/>
			</TabsContent>
			<TabsContent value="tab-4" p="0">
				<Flex direction={{ base: 'column' }} gap="5" mt="8">
					{userId && (
						<UserPerformanceChart userId={userId} userName={userName} />
					)}
					<UserPerformanceTable />
				</Flex>
			</TabsContent>
		</TabsRoot>
	);
};
