import { Stack } from '@chakra-ui/react';
import { PathsApiV1SessionsGetParametersQueryDateFilter } from '@jam/api-sdk/api';
import {
	TabsContent,
	TabsIndicator,
	TabsList,
	TabsRoot,
	TabsTrigger,
} from '@jam/front-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useActiveTab } from '../../common/hooks/useActiveTab';
import { useOriginPageBreadcrumbs } from '../../common/hooks/useOriginPageBreadcrumbs';
import { TeamsFilteringToolbar } from '../../features/dashboard/components/manager/common/TeamsFilteringToolbar';
import { RolePlayAcrossUsersTable } from '../../features/dashboard/components/manager/team-activity/RoleplayAcrossUsersTable';
import { TeamActivity } from '../../features/dashboard/components/manager/team-activity/TeamActivity';
import { useGetOrgTeamsQuery } from '../../redux/api/crmApi';
import { BasePage } from '../common/BasePage';

export const ManagerDashboard = () => {
	const { t } = useTranslation(['dashboard', 'common']);
	const [selectedTeamId, setSelectedTeamId] = useState<string | undefined>(
		undefined
	);
	const { activeTab, setActiveTab } = useActiveTab();
	const [selectedDateFilter, setSelectedDateFilter] = useState<
		PathsApiV1SessionsGetParametersQueryDateFilter | undefined
	>(PathsApiV1SessionsGetParametersQueryDateFilter.all_time);
	const { data: teams } = useGetOrgTeamsQuery();

	const steps = useOriginPageBreadcrumbs(
		'manager-dashboard',
		'/dashboard',
		t('common:activity')
	);

	return (
		<BasePage
			title={t('dashboard:activity_dashboard') ?? ''}
			subtitles={[t('dashboard:activity_dashboard_description') ?? '']}
			breadcrumbsProps={{
				items: steps,
			}}
		>
			<Stack direction="column" gap="5">
				<TeamsFilteringToolbar
					selectedDateFilter={selectedDateFilter}
					setSelectedDateFilter={setSelectedDateFilter}
					selectedTeamId={selectedTeamId}
					setSelectedTeamId={setSelectedTeamId}
					teams={teams ?? []}
					showTimeFilter={activeTab === 1}
				/>
			</Stack>
			<TabsRoot
				lazyMount
				value={activeTab === null ? 'overview' : `tab-${activeTab}`}
				onValueChange={(value) => {
					const index =
						value.value === 'overview'
							? null
							: parseInt(value.value?.split('-')[1]);
					setActiveTab(index, 'replaceIn');
				}}
			>
				<TabsList>
					<TabsTrigger value="overview">
						{t('dashboard:overview_tab_title')}
					</TabsTrigger>
					<TabsTrigger value="tab-1">
						{t('dashboard:all_rounds_played_tab_title')}
					</TabsTrigger>
					<TabsIndicator />
				</TabsList>
				<TabsContent value="overview" px="0" py="8">
					<TeamActivity selectedTeamId={selectedTeamId} />
				</TabsContent>
				<TabsContent value="tab-1" px="0" py="8">
					<RolePlayAcrossUsersTable
						selectedDateFilter={selectedDateFilter}
						teamId={selectedTeamId}
					/>
				</TabsContent>
			</TabsRoot>
		</BasePage>
	);
};
