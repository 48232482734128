import {
	PathsApiV1SessionsGetParametersQueryDateFilter,
	PathsApiV1SessionsGetParametersQueryType,
	SchemaCompletedUserActivityReposeDto,
	SchemaCompletionsWithTrendsResponseDto,
	SchemaDetailedUserOverviewResponseDto,
	SchemaKeyStoryForImprovementDto,
	SchemaMemberPerformanceResponseDto,
	SchemaMissionPerformanceResponseDto,
	SchemaSessionsTeamActivityReposeDto,
	SchemaTaskPerformanceResponseDto,
	SchemaUserOverviewDto,
	SchemaUserPerformanceByRoundResponseDto,
	SchemaUserPerformanceResponseDto,
	SchemaWeeklyActivityAcrossAllUsersResponseDto,
} from '@jam/api-sdk/api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from './buildBaseQuery';

const createTeamLink = (
	link: string,
	teamId: string | undefined,
	extraParams?: Record<string, string>
): string => {
	// Start with the base link, optionally adding the teamId parameter
	const fullLink = teamId ? `${link}?teamId=${teamId}` : link;

	// If extra parameters are provided, append them correctly
	if (extraParams) {
		const params = new URLSearchParams(extraParams);
		// Use `?` if no `teamId` is present, otherwise `&`
		return `${fullLink}${teamId ? '&' : '?'}${params.toString()}`;
	}

	return fullLink;
};

const api = createApi({
	reducerPath: 'dashboard-api',
	baseQuery: buildBaseQuery('/api/v1'),
	endpoints: (builder) => ({
		getSessionActivity: builder.query<
			SchemaCompletedUserActivityReposeDto,
			string | undefined
		>({
			query: (teamId: string | undefined) =>
				createTeamLink('user-activity/session-activity', teamId),
		}),
		getTeamCompletions: builder.query<
			SchemaCompletionsWithTrendsResponseDto,
			string | undefined
		>({
			query: (teamId: string | undefined) =>
				createTeamLink('team-activity/completions', teamId),
		}),
		getWeeklyActivity: builder.query<
			SchemaWeeklyActivityAcrossAllUsersResponseDto,
			string | undefined
		>({
			query: (teamId: string | undefined) =>
				createTeamLink('team-activity/weekly-activity', teamId),
		}),
		getRolePlayActivity: builder.query<
			SchemaSessionsTeamActivityReposeDto[],
			{
				teamId?: string;
				dateFilter?: PathsApiV1SessionsGetParametersQueryDateFilter;
				type?: PathsApiV1SessionsGetParametersQueryType;
			}
		>({
			// Generate the query URL with both teamId and dateFilter
			query: ({
				teamId,
				dateFilter = PathsApiV1SessionsGetParametersQueryDateFilter.all_time,
				type = PathsApiV1SessionsGetParametersQueryType.ROLE_PLAY,
			}) =>
				createTeamLink('team-activity/all-sessions', teamId, {
					dateFilter,
					type,
				}),
		}),
		getDetailedUserKeyMissionsForImprovement: builder.query<
			SchemaKeyStoryForImprovementDto[],
			string
		>({
			query: (userId: string) =>
				`detailed-user-activity/key-stories?userId=${userId}`,
		}),
		getDetailedUserActivityOverview: builder.query<
			SchemaDetailedUserOverviewResponseDto,
			string
		>({
			query: (userId: string) =>
				`detailed-user-activity/overview?userId=${userId}`,
		}),
		getDetailedUserPerformance: builder.query<
			SchemaUserPerformanceResponseDto[],
			string
		>({
			query: (userId: string) =>
				`detailed-user-activity/performance?userId=${userId}`,
		}),
		getDetailedUserCompletions: builder.query<
			SchemaCompletionsWithTrendsResponseDto,
			string
		>({
			query: (userId: string) =>
				`detailed-user-activity/completions?userId=${userId}`,
		}),
		getUsersOverview: builder.query<
			SchemaUserOverviewDto[],
			string | undefined
		>({
			query: (teamId: string | undefined) =>
				createTeamLink('team-activity/users-overview', teamId),
		}),
		getUserPerformanceByRound: builder.query<
			SchemaUserPerformanceByRoundResponseDto[],
			{
				users: string[];
				missionId: string;
			}
		>({
			query: ({ users, missionId }) =>
				`team-performance/user-performance-by-round?users=${users.join(',')}&missionId=${missionId}`,
		}),
		getTeamPerformanceByMember: builder.query<
			SchemaMemberPerformanceResponseDto[],
			void
		>({
			query: () => 'team-performance/performance-by-member',
		}),
		getTeamPerformanceByMission: builder.query<
			SchemaMissionPerformanceResponseDto[],
			void
		>({
			query: () => 'team-performance/performance-by-mission',
		}),
		getTeamPerformanceByTask: builder.query<
			SchemaTaskPerformanceResponseDto[],
			void
		>({
			query: () => 'team-performance/performance-by-task',
		}),
	}),
});

export const {
	useGetSessionActivityQuery,
	useGetTeamCompletionsQuery,
	useGetWeeklyActivityQuery,
	useGetRolePlayActivityQuery,
	useGetDetailedUserActivityOverviewQuery,
	useGetDetailedUserKeyMissionsForImprovementQuery,
	useGetDetailedUserCompletionsQuery,
	useGetDetailedUserPerformanceQuery,
	useGetUsersOverviewQuery,
	useGetTeamPerformanceByMemberQuery,
	useGetTeamPerformanceByMissionQuery,
	useGetTeamPerformanceByTaskQuery,
	useGetUserPerformanceByRoundQuery,
} = api;

export default api;
