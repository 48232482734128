import { Text } from '@chakra-ui/react';
import {
	TabsContent,
	TabsIndicator,
	TabsList,
	TabsRoot,
	TabsTrigger,
} from '@jam/front-library';
import { useTranslation } from 'react-i18next';
import { AppLayout } from '../common/components/AppLayout';
import { useActiveTab } from '../common/hooks/useActiveTab';
import { MissionList } from '../features/course-catalog/components/MissionList';
import { TrackList } from '../features/course-catalog/components/TrackList';
import { Salutation } from '../features/home/components/Salutation';

export const CourseCatalogPage = () => {
	const { t } = useTranslation('course-catalog');
	const { activeTab, setActiveTab } = useActiveTab();
	return (
		<AppLayout>
			<Salutation text={t('course_catalog')} />
			<Text color="#757575" fontSize="16px" maxW="700px">
				{t('course_catalog_description')}
			</Text>
			<TabsRoot
				mt="8"
				lazyMount
				value={activeTab?.toString() ?? '0'}
				onValueChange={({ value }) => {
					setActiveTab(value === '0' ? null : parseInt(value), 'replaceIn');
				}}
			>
				<TabsList>
					<TabsTrigger value={'0'}>{t('tracks')}</TabsTrigger>
					<TabsTrigger value={'1'}>{t('missions')}</TabsTrigger>
					<TabsIndicator />
				</TabsList>
				<TabsContent value={'0'} px="0" py="8">
					<TrackList />
				</TabsContent>
				<TabsContent value={'1'} px="0" py="8">
					<MissionList />
				</TabsContent>
			</TabsRoot>
		</AppLayout>
	);
};
