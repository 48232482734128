import { Input } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	Button,
	DialogBackdrop,
	DialogBody,
	DialogCloseTrigger,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogRoot,
	DialogTitle,
	Field,
	Text,
} from '@jam/front-library';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const orgSchema = z.object({
	name: z.string().min(1),
});

type Org = z.infer<typeof orgSchema>;

export const OrganizationCreateModal = ({
	isOpen,
	onClose,
	onSubmit,
}: {
	isOpen: boolean;
	onClose: () => void;
	onSubmit: (data: Org) => Promise<void>;
}) => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { isValid, isSubmitting },
	} = useForm<Org>({
		resolver: zodResolver(orgSchema),
	});

	const onCloseHandler = () => {
		reset();
		onClose();
	};

	return (
		<DialogRoot
			size={'lg'}
			open={isOpen}
			onOpenChange={({ open }) => {
				if (!open) {
					onCloseHandler();
				}
			}}
		>
			<DialogBackdrop />
			<DialogContent>
				<DialogHeader>
					<DialogTitle fontSize={'2xl'}>Create organization</DialogTitle>
				</DialogHeader>
				<DialogCloseTrigger />
				<DialogBody pb={5}>
					<Field as="fieldset" label={'Name'}>
						<Input borderRadius={'full'} {...register('name')} />
					</Field>
				</DialogBody>
				<DialogFooter>
					<Button
						id="submit-org-data"
						type="submit"
						loading={isSubmitting}
						disabled={!isValid}
						onClick={() => {
							if (!isValid) return;

							void handleSubmit(onSubmit)();
							onCloseHandler();
						}}
					>
						Submit
					</Button>
					<Button
						variant="tertiary"
						onClick={() => {
							onCloseHandler();
						}}
					>
						<Text>Cancel</Text>
					</Button>
				</DialogFooter>
			</DialogContent>
		</DialogRoot>
	);
};
