import { Flex } from '@chakra-ui/react';
import { SchemaStoryResponseDto } from '@jam/api-sdk/api';
import {
	StoryStatus,
	useStoryStatus,
} from '../../../common/hooks/useStoryStatus';
import { useAppSelector } from '../../../redux/hooks';
import { selectCurrentCallStatus } from '../../../redux/slice';
import { CallButton } from './CallButton';
import { CallStatus } from './CallStatus';
import { NextStory } from './NextStory';

type CallerButtonsProps = {
	numberOfRounds: number;
	story: SchemaStoryResponseDto;
	onFinishCall: () => Promise<void>;
};

export const CallerButtons = ({
	story,
	numberOfRounds,
	onFinishCall,
}: CallerButtonsProps) => {
	const callStatus = useAppSelector(selectCurrentCallStatus);
	const storyStatus = useStoryStatus(story.id);

	const isCallInProgress =
		callStatus === CallStatus.started || callStatus === CallStatus.loading;

	return (
		<Flex flexShrink={0} gap="2" alignItems={'center'} h="fit-content">
			{!isCallInProgress && (
				<NextStory isPrimary={storyStatus === StoryStatus.COMPLETED} />
			)}

			<CallButton
				firstTime={numberOfRounds < 1}
				isPrimary={storyStatus !== StoryStatus.COMPLETED}
				story={story}
				onFinishCall={onFinishCall}
			/>
		</Flex>
	);
};
