import React from 'react';
import { Button } from '@jam/front-library';

interface StoryCardButtonProps {
	label: string;
	icon?: React.ReactNode;
	onClick?: () => void;
	disabled?: boolean;
}

export const StoryCardButton = ({
	icon,
	label,
	onClick,
	disabled,
}: StoryCardButtonProps) => {
	return (
		<Button
			id="exit-button"
			onClick={onClick}
			disabled={disabled}
			icon={icon}
			iconPosition={'start'}
			size="full"
			variant="secondary"
		>
			{label}
		</Button>
	);
};
