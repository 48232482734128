import {
	TabsContent,
	TabsIndicator,
	TabsList,
	TabsRoot,
	TabsTrigger,
} from '@jam/front-library';
import { useTranslation } from 'react-i18next';
import { useIsOwner } from '../common/hooks/useCheckRole';
import { useOriginPageBreadcrumbs } from '../common/hooks/useOriginPageBreadcrumbs';
import { InviteUsers } from '../features/dashboard/components/team/common/InviteUsers';
import { PendingInvites } from '../features/dashboard/components/team/pending-invites/PendingInvites';
import { RegisteredMembers } from '../features/dashboard/components/team/registered-members/RegisteredMembers';
import { ContentAssignmentTab } from '../features/settings/components/ContentAssignmentTab';
import { useGetOrgTeamsQuery } from '../redux/api/crmApi';
import { BasePage } from './common/BasePage';

export const TeamsPage = () => {
	const { t } = useTranslation(['team', 'settings']);
	const isOwner = useIsOwner();
	const { data: teams } = useGetOrgTeamsQuery();
	const steps = useOriginPageBreadcrumbs('teams', '/teams', t('team:team'));

	return (
		<BasePage
			title={t('team:team_members')}
			titleActions={<InviteUsers />}
			breadcrumbsProps={{
				items: steps,
			}}
		>
			<TabsRoot defaultValue="registered" lazyMount>
				<TabsList>
					<TabsTrigger value="registered">
						{t('team:registered_members')}
					</TabsTrigger>
					<TabsTrigger value="pending">{t('team:pending_invites')}</TabsTrigger>
					{isOwner && (
						<TabsTrigger value="content">
							{t('settings:content-assignment')}
						</TabsTrigger>
					)}
					<TabsIndicator />
				</TabsList>
				<TabsContent value="registered" px="0" py="8">
					<RegisteredMembers teams={teams ?? []} />
				</TabsContent>
				<TabsContent value="pending" px="0" py="8">
					<PendingInvites />
				</TabsContent>
				{isOwner && (
					<TabsContent value="content" px="0" py="8">
						<ContentAssignmentTab />
					</TabsContent>
				)}
			</TabsRoot>
		</BasePage>
	);
};
