import {
	SchemaCreateOrganizationRequestDto,
	SchemaOrganizationResponseDto,
	SchemaPaginatedOrganizationResponseDto,
	SchemaSagaResponseDto,
	SchemaUpdateOrganizationDto,
} from '@jam/api-sdk/api';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

function buildBaseQuery(path: string) {
	return fetchBaseQuery({
		baseUrl: `${
			import.meta.env.VITE_SALES_ENABLEMENT_PLATFORM_NEST_API_BASE_URL as string
		}${path}`,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).applicationState.accessToken;

			if (token) {
				headers.set('Authorization', `Bearer ${token}`);
			}

			return headers;
		},
	});
}

const api = createApi({
	reducerPath: 'admin-api',
	baseQuery: buildBaseQuery('/api/v1'),
	tagTypes: ['Organization', 'Saga'],
	endpoints: (builder) => ({
		getAllOrganizations: builder.query<SchemaOrganizationResponseDto[], void>({
			query: () => `organizations/all`,
			providesTags: ['Organization'],
		}),
		getOrganizations: builder.query<
			SchemaPaginatedOrganizationResponseDto,
			{
				ids?: string[];
				page?: number;
				limit?: number;
				organizationNameSearch?: string;
			} | void // Make it optional so you can still call without params
		>({
			query: (params) => ({
				url: 'organizations',
				params: {
					...params,
				},
			}),
			providesTags: ['Organization'],
		}),
		createOrganization: builder.mutation<
			SchemaOrganizationResponseDto,
			SchemaCreateOrganizationRequestDto
		>({
			query: (body) => ({
				url: `organizations`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Organization'],
		}),
		updateOrganization: builder.mutation<
			void,
			{ id: string; body: SchemaUpdateOrganizationDto }
		>({
			query: ({ id, body }) => ({
				url: `organizations/${id}`,
				method: 'PATCH',
				body,
			}),
			invalidatesTags: ['Organization'],
		}),
		getSagas: builder.query<SchemaSagaResponseDto[], void>({
			query: () => `sagas`,
			providesTags: (result) =>
				result ? result.map(({ id }) => ({ type: 'Saga', id })) : [],
		}),
	}),
});

export const {
	useGetOrganizationsQuery,
	useGetAllOrganizationsQuery,
	useGetSagasQuery,
	useCreateOrganizationMutation,
	useUpdateOrganizationMutation,
} = api;
export default api;
