import { Flex } from '@chakra-ui/react';
import { CaretDoubleLeft, CaretDoubleRight } from '@phosphor-icons/react';

export const ExpandToggle = ({
	right,
	top,
	onToggle,
	isOpen,
}: {
	right: string;
	top: string;
	onToggle: () => void;
	isOpen: boolean;
}) => {
	return (
		<Flex
			aria-label="toggle menu"
			justify={'center'}
			alignItems={'center'}
			right={right}
			top={top}
			position={'absolute'}
			w="6"
			h="6"
			bgColor="#FAFAFA"
			borderRadius={'100%'}
			onClick={onToggle}
			cursor={'pointer'}
		>
			{isOpen ? (
				<CaretDoubleLeft weight="bold" color="#757575" size={14} />
			) : (
				<CaretDoubleRight weight="bold" color="#757575" size={14} />
			)}
		</Flex>
	);
};
