import { useTranslation } from 'react-i18next';
import { NavButton } from './NavButton';
import { SidebarEntry } from './SideBarNavigation';

interface Props<DataType> {
	isOpen: boolean;
	data: DataType;
	item: SidebarEntry<DataType>;
	itemKey: string;
	onEventClick: (eventName: string) => void;
}

export const SideBarNavigationItem = <DataType,>({
	isOpen,
	data,
	item,
	itemKey,
	onEventClick,
}: Props<DataType>) => {
	const { t } = useTranslation('common');
	const Icon = item.icon;
	const onClick = () => {
		if (item.hrefType === 'event') {
			onEventClick(item.href);
		}
	};
	return (
		<NavButton
			key={itemKey}
			href={item.hrefType !== 'event' ? item.href : undefined}
			onClick={item.hrefType === 'event' ? onClick : undefined}
			label={t(item.label)}
			target={item.target}
			hideLabel={!isOpen}
			hidden={item.hidden ? item.hidden(data) : false}
			disabled={item.disabled ? item.disabled(data) : false}
			icon={Icon ? <Icon size={16} weight="bold" /> : undefined}
		/>
	);
};
