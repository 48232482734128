import { Flex, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Plus } from '@phosphor-icons/react';
import { AddUserModal } from '../../../../settings/components/AddUserModal';
import { Button } from '@jam/front-library';

export const InviteUsers = () => {
	const { t } = useTranslation(['team', 'settings']);
	const { open: isOpen, onOpen, onClose } = useDisclosure();

	return (
		<Flex gap="5">
			<Button
				id="add-user-button"
				type="submit"
				onClick={() => {
					onOpen();
				}}
				icon={<Plus size={15} />}
			>
				{t('settings:add-user')}
			</Button>
			<AddUserModal isOpen={isOpen} onClose={onClose} />
		</Flex>
	);
};
