import { Box, SimpleGrid, Skeleton } from '@chakra-ui/react';
import { useAuthInfo } from '@propelauth/react';
import { StoryCard } from '../../../common/components/story-detail/StoryCard';
import { StartOrResumeStoryButton } from '../../../common/components/story-detail/buttons/StartOrResumeStoryButton';
import { useGetStoriesQuery } from '../../../redux/api/cmsApi';

const LoadingState = () => {
	return (
		<SimpleGrid
			role="loading-state"
			gap={4}
			columns={[1, 1, 2, 2, 3]}
			w="fit-content"
		>
			{Array.from({ length: 6 }).map((_, index) => (
				<Skeleton borderRadius="10px" key={index} height="420px" w="284px" />
			))}
		</SimpleGrid>
	);
};

export const MissionList = () => {
	const { data: missions, isLoading } = useGetStoriesQuery();
	const { user } = useAuthInfo();

	if (isLoading) {
		return <LoadingState />;
	}

	if (!missions || missions.length === 0) {
		return <Box role="no-missions-found">No missions found</Box>;
	}

	return (
		<SimpleGrid
			role="mission-list"
			gap={4}
			columns={[1, 1, 2, 2, 3]}
			w="fit-content"
		>
			{missions.map((mission) => (
				<StoryCard
					key={mission.id}
					userId={user?.userId}
					story={mission}
					renderStoryButton={(storyId) => (
						<StartOrResumeStoryButton storyId={storyId} />
					)}
				/>
			))}
		</SimpleGrid>
	);
};
