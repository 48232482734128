import { Box, Flex } from '@chakra-ui/react';
import { SkeletonText } from '@jam/front-library';
import { StartOrResumeStoryButton } from '../../../common/components/story-detail/buttons/StartOrResumeStoryButton';
import { TrackAccordion } from '../../../common/components/track-detail/TrackAccordion';
import { useGetJourneysQuery } from '../../../redux/api/cmsApi';

const LoadingState = () => {
	return <SkeletonText role="loading-state" noOfLines={1} height={'30px'} />;
};

export const TrackList = () => {
	const { data: journeys, isLoading } = useGetJourneysQuery();

	if (isLoading) {
		return <LoadingState />;
	}

	if (!journeys || journeys.length === 0) {
		return <Box role="no-journeys-found">No journeys found</Box>;
	}

	return (
		<Flex role="track-list" flexDirection="column" gap="4">
			{journeys.map((journey) => (
				<TrackAccordion
					key={journey.id}
					hideStatus={false}
					journey={journey}
					renderStoryButton={(storyId) => (
						<StartOrResumeStoryButton storyId={storyId} />
					)}
				/>
			))}
		</Flex>
	);
};
