import { useDisclosure } from '@chakra-ui/react';
import { SchemaStoryResponseDto } from '@jam/api-sdk/api';
import { toaster } from '@jam/front-library';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';
import { useAppSelector } from '../../../redux/hooks';
import { selectCurrentCallStatus } from '../../../redux/slice';
import { useMicrophonePermission } from '../hooks/useMicrophonePermission';
import { useVapi } from '../hooks/useVapi';
import { AnimatedCallButton } from './AnimatedCallButton';
import { CallStatus } from './CallStatus';
import CallTooShortDialog from './CallTooShortDialog';
import { LeaveWarningModal } from './LeaveWarningModal';
import { MicrophonePermission } from './MicrophonePermission';

type CallButtonProps = {
	firstTime: boolean;
	isPrimary: boolean;
	story: SchemaStoryResponseDto;
	onFinishCall: () => Promise<void>;
};

export const CallButton = ({
	firstTime,
	isPrimary,
	story,
	onFinishCall,
}: CallButtonProps) => {
	const { t } = useTranslation(['call', 'story']);
	const callStatus = useAppSelector(selectCurrentCallStatus);
	const { permissionState, requestMicrophone } = useMicrophonePermission();
	const {
		open: isTooShortOpen,
		onOpen: onTooShortOpen,
		onClose: onTooShortClose,
	} = useDisclosure();
	const { start, stop, deleteSession, error, callDurationSec } = useVapi({
		story,
	});
	const {
		open: isLeavingOpen,
		onClose: onLeavingClose,
		onOpen: onLeavingOpen,
	} = useDisclosure();
	const blocker = useBlocker(
		({ currentLocation, nextLocation }) =>
			(callStatus === CallStatus.loading ||
				callStatus === CallStatus.started) &&
			currentLocation.pathname !== nextLocation.pathname
	);

	const onClickHandler = useCallback(() => {
		if (permissionState !== 'granted') {
			requestMicrophone();
			return;
		}

		if (
			callStatus === CallStatus.notStarted ||
			// user can start a new call
			callStatus === CallStatus.finished
		) {
			void start();
		} else if (callStatus === CallStatus.started) {
			stop();
		}
	}, [callStatus, permissionState, start, stop, requestMicrophone]);

	const finishCallProcedure = useCallback(async () => {
		await onFinishCall();
	}, [onFinishCall]);

	const onCancelCallHandler = useCallback(async (): Promise<void> => {
		if (
			callStatus === CallStatus.started ||
			callStatus === CallStatus.loading
		) {
			stop();
		}
		await deleteSession();
	}, [callStatus, stop, deleteSession]);

	useEffect(() => {
		if (callStatus !== CallStatus.finished || callDurationSec === null) return;

		if (callDurationSec >= 20) {
			void finishCallProcedure();
		} else {
			onTooShortOpen();
		}
	}, [callStatus, callDurationSec]);

	useEffect(() => {
		if (!error) return;

		toaster.create({
			title: 'Error',
			description: t('call_error_text'),
			type: 'error',
		});
	}, [error]);

	useEffect(() => {
		if (blocker.state === 'blocked') {
			onLeavingOpen();
		}
	}, [callStatus, blocker.state]);

	if (callStatus === null) {
		return null;
	}

	return (
		<>
			<MicrophonePermission />
			<LeaveWarningModal
				isOpen={isLeavingOpen}
				onExit={() => {
					onLeavingClose();
					void onCancelCallHandler();
					blocker.proceed?.();
				}}
				onContinue={() => {
					onLeavingClose();
					blocker.reset?.();
				}}
			/>
			<CallTooShortDialog
				isOpen={isTooShortOpen}
				onCancelFeedback={() => {
					onTooShortClose();
					void onCancelCallHandler();
				}}
				onGenerateFeedback={() => {
					onTooShortClose();
					void finishCallProcedure();
				}}
			/>
			<AnimatedCallButton
				firstTime={firstTime}
				isPrimary={isPrimary}
				callStatus={callStatus}
				onCallerClick={onClickHandler}
			/>
		</>
	);
};
