import { Skeleton, Stack } from '@chakra-ui/react';
import { X } from '@phosphor-icons/react';
import { useParams } from 'react-router-dom';
import { AppLayout } from '../../common/components/AppLayout';
import { useGetUserById } from '../../common/hooks/useGetUserById';
import { usePageBreadcrumbs } from '../../common/hooks/usePageBreadcrumbs';
import { getUserDisplayName } from '../../common/utils/stringUtil';
import { CallDetails } from '../../features/call/components/CallDetails';
import { CallHeader } from '../../features/call/components/CallHeader';
import { useGetStoryQuery } from '../../redux/api/cmsApi';
import { useGetSessionQuery } from '../../redux/api/sessionApi';
import { BasePage } from '../common/BasePage';

const LoadingState = () => {
	return (
		<AppLayout hideSidebar fullWidth>
			<CallHeader />
			<Stack direction="column" h="100%" gap={10} p={10}>
				<Skeleton borderRadius={'8px'} h="200px" flex={1} />
			</Stack>
		</AppLayout>
	);
};

const useViewSessionPageBreadcrumbs = (sessionId?: string, userId?: string) => {
	const user = useGetUserById(userId);
	return usePageBreadcrumbs(
		[
			{
				title: getUserDisplayName(user?.firstName, user?.lastName),
				id: `call-${userId ?? ''}`,
				link: `/real-call/user/${userId ?? ''}/session/${sessionId ?? ''}`,
			},
		],
		!userId || !sessionId
	);
};

const useFetchPageData = (sessionId?: string) => {
	const { data: session, isLoading: isSessionLoading } = useGetSessionQuery(
		sessionId ?? '',
		{
			skip: !sessionId,
		}
	);
	const { data: story, isLoading } = useGetStoryQuery(
		session?.storyReferenceId ?? '',
		{
			skip: !session,
		}
	);

	return {
		session,
		story,
		isLoading,
		isSessionLoading,
	};
};

const usePageData = () => {
	const { userId, sessionId } = useParams<{
		userId: string;
		sessionId: string;
	}>();

	const { session, story, isLoading, isSessionLoading } =
		useFetchPageData(sessionId);

	return {
		userId,
		session,
		isSessionLoading,
		story,
		isLoading,
	};
};

export const ViewRealCallSessionPage = () => {
	const { userId, session, isSessionLoading, story, isLoading } = usePageData();

	const steps = useViewSessionPageBreadcrumbs(session?.id, userId);

	if (isLoading || isSessionLoading) {
		return <LoadingState />;
	}

	if (!session || !userId) {
		return null;
	}

	return (
		<BasePage
			breadcrumbsProps={{
				items: steps,
				icon: X,
				showDefaultBack: true,
			}}
			title={''}
			hideSidebar
			fullWidth
		>
			<CallDetails story={story} session={session} />
		</BasePage>
	);
};
