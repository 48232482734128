import { Box, Flex, Link } from '@chakra-ui/react';
import { SelectOrg } from './SelectOrg';

export const AdminHeader = () => {
	return (
		<Flex
			as="header"
			position="sticky"
			bg="white"
			backdropFilter="saturate(180%) blur(10px)"
			w="100%"
			borderBottom={'1px solid'}
			padding={2}
		>
			<Box w="100%">
				<Link href="/admin/org">Organizations</Link>
			</Box>
			<Box>
				<SelectOrg />
			</Box>
		</Flex>
	);
};
