import { Stack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { PathsApiV1AssignmentsGetParametersQueryReferenceType } from '@jam/api-sdk/api';
import {
	DialogBackdrop,
	DialogBody,
	DialogCloseTrigger,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogRoot,
	DialogTitle,
	Field,
	Select,
	Text,
	toaster,
	Button,
} from '@jam/front-library';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z, ZodType } from 'zod';
import {
	useGetJourneysQuery,
	useGetStoriesQuery,
} from '../../../redux/api/cmsApi';
import { useAssignContentMutation } from '../../../redux/api/contentAllocationApi';

type AssignContentModalProps = {
	userIds: string[];
	isOpen: boolean;
	onClose: () => void;
};

type ContentSelectionData = {
	contentId: string;
};

const ContentSelectionDataSchema: ZodType<ContentSelectionData> = z.object({
	contentId: z.string().min(1),
});

export const AssignContentModal = ({
	userIds,
	isOpen,
	onClose,
}: AssignContentModalProps) => {
	const { t } = useTranslation(['settings', 'common']);
	const contentRef = React.useRef<HTMLDivElement>(null);
	const { data: journeys } = useGetJourneysQuery();
	const { data: stories } = useGetStoriesQuery();
	const {
		handleSubmit,
		reset,
		formState: { isValid, isSubmitting },
		control,
	} = useForm<ContentSelectionData>({
		resolver: zodResolver(ContentSelectionDataSchema),
	});
	const [assignContent] = useAssignContentMutation();

	const onSubmit = async (data: ContentSelectionData) => {
		const [type, id] = data.contentId.split(':');
		await assignContent({
			userIds,
			reference: {
				type:
					type === 'journey'
						? PathsApiV1AssignmentsGetParametersQueryReferenceType.journey
						: PathsApiV1AssignmentsGetParametersQueryReferenceType.story,
				id,
			},
		});

		onClose();
		reset();
		toaster.create({
			title: t('content-assignment-successfully'),
			type: 'success',
			duration: 3000,
			meta: {
				closable: true,
			},
		});
	};

	if (!journeys || !stories) return null;

	return (
		<DialogRoot
			size="lg"
			open={isOpen}
			onOpenChange={({ open }) => {
				if (!open) {
					reset();
					onClose();
				}
			}}
		>
			<DialogBackdrop />
			<DialogContent ref={contentRef}>
				<DialogHeader>
					<DialogTitle fontSize={'2xl'}>{t('assign-content')}</DialogTitle>
					<DialogCloseTrigger />
				</DialogHeader>
				<DialogBody pb={6}>
					<Stack gap={4}>
						<Controller
							control={control}
							name="contentId"
							render={({ field }) => (
								<Field label={t('select-content')}>
									<Select
										portalRef={contentRef}
										name={field.name}
										value={field.value}
										onValueChange={(value) => field.onChange(value)}
										borderRadius={'full'}
										items={[
											// Transform journeys into items with group
											...journeys.map((journey) => ({
												value: `journey:${journey.id}`,
												label: journey.title,
												group: t('common:tracks') ?? 'Tracks',
											})),
											// Transform stories into items with group
											...stories.map((story) => ({
												value: `story:${story.id}`,
												label: story.title,
												group: t('common:missions') ?? 'Stories',
											})),
										]}
									/>
								</Field>
							)}
						/>
					</Stack>
				</DialogBody>
				<DialogFooter>
					<Button
						id="assign-content-button"
						type="submit"
						loading={isSubmitting}
						disabled={!isValid}
						onClick={() => {
							if (!isValid) return;
							void handleSubmit(onSubmit)();
						}}
					>
						{t('assign')}
					</Button>
					<Button
						variant={'tertiary'}
						onClick={() => {
							onClose();
							reset();
						}}
					>
						<Text>{t('cancel')}</Text>
					</Button>
				</DialogFooter>
			</DialogContent>
		</DialogRoot>
	);
};
