import { Flex, Image } from '@chakra-ui/react';
import {
	ProgressCircleRing,
	ProgressCircleRoot,
	Text,
} from '@jam/front-library';
import { useTranslation } from 'react-i18next';

type FeedbackNotFinishedProps = {
	status: 'PENDING' | 'STALLED';
};

const textToStatusMap: Record<FeedbackNotFinishedProps['status'], string> = {
	PENDING: 'feedback_not_started_text',
	STALLED: 'feedback_error_stalled_text',
};

export const FeedbackNotFinished = ({ status }: FeedbackNotFinishedProps) => {
	const { t } = useTranslation('call');

	return (
		<Flex
			h="100%"
			direction="column"
			gap="10"
			justifyContent={'center'}
			alignItems={'center'}
		>
			{status === 'PENDING' ? (
				<ProgressCircleRoot size="lg" value={null} color="green.400">
					<ProgressCircleRing css={{ '--thickness': '3px' }} />
				</ProgressCircleRoot>
			) : (
				<Image src="/dark-logo.svg" w="60px" />
			)}
			<Text color="#000000CC" size="lg">
				{t(textToStatusMap[status])}
			</Text>
		</Flex>
	);
};
