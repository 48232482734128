import { Flex } from '@chakra-ui/react';
import { Avatar, Text } from '@jam/front-library';
import { DotsThreeVertical } from '@phosphor-icons/react';
import { useAuthInfo } from '@propelauth/react';
import { UserContextMenu } from '../../../features/call/components/UserContextMenu';
import { useUserOrg } from '../../hooks/useUserOrg';

const maxChars = 10;

export const ProfileButton = ({ isOpen }: { isOpen: boolean }) => {
	const { user: authUser } = useAuthInfo();
	const org = useUserOrg();

	const name = `${authUser?.firstName || ''} ${authUser?.lastName || ''}`;
	const profileImage = authUser?.pictureUrl || '';
	const orgName = org?.orgName || '';

	// Decide whether to display full name or first name only
	const displayName =
		name.length <= maxChars ? name : authUser?.firstName || '';

	return (
		<UserContextMenu placement="right" ml={isOpen ? '5' : '2'}>
			<Flex
				cursor={'pointer'}
				justifyContent={!isOpen ? 'center' : 'start'}
				width={isOpen ? '100%' : 'fit-content'}
				border="1px"
				borderRadius={'8px'}
				gap={2}
				px={1}
				pr={2}
				py={1}
				alignItems={'center'}
			>
				<Avatar aria-label="profile picture" size={'sm'} src={profileImage} />
				{isOpen && (
					<Flex flexGrow={1} alignItems={'start'} direction={'column'}>
						<Text
							truncate
							maxW="100px"
							fontSize="14px"
							fontWeight="500"
							whiteSpace="nowrap"
							overflow="hidden"
							textOverflow="ellipsis"
						>
							{displayName}
						</Text>
						<Text truncate fontSize={'12px'} color="#757575">
							{orgName}
						</Text>
					</Flex>
				)}
				{isOpen && <DotsThreeVertical size={16} />}
			</Flex>
		</UserContextMenu>
	);
};
