import { Box, Stack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { ExpandToggle } from './ExpandToggle';

type ExpandableSidebarProps = {
	isOpen: boolean;
	onToggle: () => void;
	children: string | JSX.Element | JSX.Element[];
};

const MotionBox = motion(Box);

export const ExpandableSidebar = ({
	isOpen,
	onToggle,
	children,
}: ExpandableSidebarProps) => {
	return (
		<Box position="relative" pr="10px">
			<MotionBox
				px={2}
				py="5"
				bg="#FAFAFA"
				overflow="auto"
				height="100vh"
				borderRight={'1px'}
				width={'220px'}
				borderColor={'#E6E6E6'}
				flexShrink={0}
				initial={{ width: isOpen ? '220px' : '70px' }}
				animate={{ width: isOpen ? '220px' : '70px' }}
				transition={{ duration: 0.3 }}
			>
				<Stack
					role="navigation"
					height={'100%'}
					justify="space-between"
					gap="1"
					width="full"
				>
					<Stack h="100%" gap="10">
						<ExpandToggle
							onToggle={onToggle}
							isOpen={isOpen}
							right={'0px'}
							top={'100px'}
						/>
						{children}
					</Stack>
				</Stack>
			</MotionBox>
		</Box>
	);
};
