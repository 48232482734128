/* eslint-disable no-restricted-syntax */
import { Flex } from '@chakra-ui/react';
import { Text } from '@jam/front-library';
import { useTranslation } from 'react-i18next';

type ContentProps = {
	translationKey: string;
};

const StepContent = ({ translationKey }: ContentProps) => {
	const { t } = useTranslation('common');

	return (
		<Flex flexDirection={'column'} gap={'8px'}>
			<Text variant="cards-h1" color="white">
				{t(`${translationKey}.title`)}
			</Text>
			<Text fontSize={'14px'} fontWeight={'400'} color="white">
				{t(`${translationKey}.description`)}
			</Text>
		</Flex>
	);
};

export default StepContent;
