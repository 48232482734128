import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsAdmin } from '../common/hooks/useIsAdmin';
import { useUserOrg } from '../common/hooks/useUserOrg';
import { useIsManager, useIsOwner } from '../common/hooks/useCheckRole';

export const DefaultPage = () => {
	const navigate = useNavigate();
	const org = useUserOrg();
	const isManager = useIsManager();
	const isOwner = useIsOwner();
	const isAdmin = useIsAdmin();
	const isOwnerOrManager = isOwner || isManager;

	useEffect(() => {
		if (isAdmin) {
			if (!org?.userAssignedRole) {
				navigate('/admin/org', { replace: true });
			} else {
				navigate('/dashboard', { replace: true });
			}
		} else if (isOwnerOrManager) {
			navigate('/dashboard', { replace: true });
		} else {
			navigate('/users/home', { replace: true });
		}
	}, [org, isAdmin, navigate, isOwnerOrManager]);

	return null;
};
