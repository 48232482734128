import { useIsManager, useIsOwner } from './useCheckRole';
import { useIsAdmin } from './useIsAdmin';
import { useIsRecruiting } from './useIsRecruiting';

export interface SidebarData {
	isOwner: boolean;
	isManager: boolean;
	isAdmin: boolean;
	isOwnerOrManager: boolean;
	isRecruiting: boolean;
}
export const useAppSidebarData: () => SidebarData = () => {
	const isOwner = useIsOwner();
	const isManager = useIsManager();
	const isAdmin = useIsAdmin();
	const isOwnerOrManager = isOwner || isManager;
	const isRecruiting = useIsRecruiting();

	return {
		isOwner,
		isManager,
		isAdmin,
		isOwnerOrManager,
		isRecruiting,
	};
};
