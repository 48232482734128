import { Box, Flex, Text } from '@chakra-ui/react';
import {
	AssignedContentResponseDTOType,
	PathsApiV1AssignmentsGetParametersQueryReferenceType,
	SchemaAssignedContentResponseDto,
	SchemaStoryResponseDto,
} from '@jam/api-sdk/api';
import { ReactElement } from 'react';
import { StoryAssignment } from './StoryAssignment';
import { TrackAssignment } from './TrackAssignment';

const LoadingState = () => {
	return <div role="placeholder">Loading...</div>;
};

type VerticalAssignmentListProps = {
	contentAllocations?: SchemaAssignedContentResponseDto[];
	activeStory?: SchemaStoryResponseDto;
	userId?: string;
	renderStoryCardButton: (storyId: string) => ReactElement;
};

export const VerticalAssignmentList = ({
	contentAllocations,
	activeStory,
	userId,
	renderStoryCardButton,
}: VerticalAssignmentListProps) => {
	if (!contentAllocations) {
		return <LoadingState />;
	}

	return (
		<>
			<Flex direction="column" gap="6">
				{contentAllocations.map((contentAllocation, index) => (
					<Flex key={contentAllocation.id} direction="row">
						<Flex direction="column" w="10%" maxW="120px">
							<Text fontSize="20px" color="#0B1215" fontWeight="500">
								{index + 1}
							</Text>
							<Text fontSize="16px" color="#757575" fontWeight="500">
								{contentAllocation.type ===
								AssignedContentResponseDTOType.refresher
									? 'Refresher'
									: 'Assignment'}
							</Text>
							<Box
								position="relative"
								mt={1}
								ml={1}
								width="2px" // Divider width
								height="100%" // Stretches to fill the container height
								backgroundImage="repeating-linear-gradient(#757575 0, #757575 6px, transparent 6px, transparent 16px)" // Adjust dot and gap sizes
								backgroundSize="2px 16px" // Width of dots and spacing
								backgroundRepeat="repeat-y" // Repeat the pattern vertically
							/>
						</Flex>
						<Box w="full">
							{contentAllocation.reference.type ===
							PathsApiV1AssignmentsGetParametersQueryReferenceType.journey ? (
								<TrackAssignment
									key={contentAllocation.id}
									userId={userId}
									activeStoryId={activeStory?.id}
									journeyId={contentAllocation.reference.id}
									completedStoryIds={contentAllocation.completedStoryIds}
									renderStoryButton={renderStoryCardButton}
								/>
							) : (
								<StoryAssignment
									key={contentAllocation.id}
									dueDate={
										contentAllocation.dueDate
											? new Date(contentAllocation.dueDate)
											: undefined
									}
									storyId={contentAllocation.reference.id}
									userId={userId}
									renderStoryButton={renderStoryCardButton}
								/>
							)}
						</Box>
					</Flex>
				))}
			</Flex>
		</>
	);
};
