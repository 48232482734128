import { Input, Stack, Textarea } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	Button,
	DialogBackdrop,
	DialogBody,
	DialogCloseTrigger,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogRoot,
	DialogTitle,
	Field,
	Select,
	Text,
} from '@jam/front-library';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z, ZodType } from 'zod';
import { useGetStoriesQuery } from '../../redux/api/cmsApi';
import { useGetManageableUsersQuery } from '../../redux/api/crmApi';
import React from 'react';

export type UploadTranscriptModalProps = {
	isOpen: boolean;
	onSubmit: (data: TranscriptData) => void | Promise<void>;
	onCancel: () => void;
};

export type TranscriptData = {
	transcript: string;
	userId: string;
	storyId: string;
};

const TranscriptDataSchema: ZodType<TranscriptData> = z.object({
	transcript: z.string().min(10),
	userId: z.string().uuid(),
	storyId: z.string(),
});

export const UploadTranscriptModal = ({
	isOpen,
	onCancel,
	onSubmit,
}: UploadTranscriptModalProps) => {
	const contentRef = React.useRef<HTMLDivElement>(null);
	const { t } = useTranslation('real-call');
	const { data: users } = useGetManageableUsersQuery();
	const { data: stories } = useGetStoriesQuery();
	const {
		register,
		handleSubmit,
		reset,
		formState: { isDirty, errors, isSubmitting, isValid },
		setValue,
		control,
	} = useForm<TranscriptData>({
		resolver: zodResolver(TranscriptDataSchema),
	});

	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const content = e.target?.result as string;
				setValue('transcript', content, { shouldDirty: true });
			};
			reader.readAsText(file);
		}
	};

	const onSubmitHandler = async (data: TranscriptData) => {
		await onSubmit(data);
		reset();
	};

	if (!users || !stories) return null;

	return (
		<DialogRoot
			size="lg"
			open={isOpen}
			onOpenChange={({ open }) => {
				if (!open) {
					reset();
					onCancel();
				}
			}}
		>
			<DialogBackdrop />
			<DialogContent ref={contentRef}>
				<DialogHeader>
					<DialogTitle fontSize={'2xl'}>
						{t('upload-transcript-title')}
					</DialogTitle>
					<DialogCloseTrigger />
				</DialogHeader>
				<DialogBody pb={6}>
					<Stack gap={4}>
						<Field label={t('upload-file-form')}>
							<Input
								data-testid="upload-file-form"
								type="file"
								accept=".txt,.json,.csv"
								onChange={handleFileUpload}
								borderRadius={'8px'}
								p={1}
							/>
						</Field>
						<Field invalid={!!errors.transcript} label={t('transcript')}>
							<Textarea
								borderRadius={'8px'}
								{...register('transcript')}
								lineClamp={10}
							/>
						</Field>
						<Controller
							control={control}
							name="userId"
							render={({ field }) => (
								<Field label={t('select-user')}>
									<Select
										portalRef={contentRef}
										name={field.name}
										value={field.value}
										onValueChange={(value) => field.onChange(value)}
										placeholder={t('select-user') ?? 'Select user'}
										items={users.map((user) => ({
											value: user.id,
											label: `${user.firstName} ${user.lastName}`,
										}))}
									/>
								</Field>
							)}
						/>
						<Controller
							control={control}
							name="storyId"
							render={({ field }) => (
								<Field label={t('select-score-card')}>
									<Select
										portalRef={contentRef}
										name={field.name}
										value={field.value}
										onValueChange={(value) => field.onChange(value)}
										placeholder={t('select-score-card') ?? 'Select score card'}
										items={stories.map((story) => ({
											value: story.id,
											label: story.title,
										}))}
									/>
								</Field>
							)}
						/>
					</Stack>
				</DialogBody>
				<DialogFooter>
					<Button
						id="add-user-button"
						type="submit"
						loading={isSubmitting}
						disabled={!isValid}
						onClick={() => {
							if (!isDirty) return;
							void handleSubmit(onSubmitHandler)();
						}}
					>
						{t('upload')}
					</Button>
					<Button
						variant="tertiary"
						onClick={() => {
							onCancel();
							reset();
						}}
					>
						<Text>{t('cancel')}</Text>
					</Button>
				</DialogFooter>
			</DialogContent>
		</DialogRoot>
	);
};
