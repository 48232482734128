import { Box } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { Button, Tooltip } from '@jam/front-library';
import { Play, SpeakerSimpleHigh } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { CallStatus } from './CallStatus';
import { useAppSelector } from '../../../redux/hooks';
import { selectBotIsSpeaking } from '../../../redux/slice';

type CallerStatusConfig = {
	buttonText: string;
	buttonBorder?: string;
	buttonVariant: 'primary' | 'secondary';
};

const statusConfigMap: Record<CallStatus, CallerStatusConfig> = {
	notStarted: {
		buttonText: 'start-call',
		buttonVariant: 'primary',
	},
	loading: {
		buttonText: 'loading-call',
		buttonVariant: 'primary',
	},
	started: {
		buttonText: 'end-call',
		buttonBorder: 'linear-gradient(180deg,  #7D4CD0, #F98CC4)',
		buttonVariant: 'secondary',
	},
	finished: {
		buttonText: 'start-again',
		buttonVariant: 'primary',
	},
};

type AnimatedCallButtonProps = {
	firstTime: boolean;
	isPrimary: boolean;
	callStatus: CallStatus;
	onCallerClick: () => void;
};

export const AnimatedCallButton = ({
	firstTime,
	isPrimary,
	callStatus,
	onCallerClick,
}: AnimatedCallButtonProps) => {
	const { t } = useTranslation(['call', 'story']);
	const botIsSpeaking = useAppSelector(selectBotIsSpeaking);

	const pulse = keyframes`
  0% { box-shadow: 0 0 0 2px rgba(204,169,44, 0.4); }
  20% { box-shadow: 0 0 0 6px rgba(204,169,44, 0.2); }
  100% { box-shadow: 0 0 0 2px rgba(204,169,44, 0.4); }
`;

	const uiState = statusConfigMap[callStatus];

	if (callStatus === CallStatus.notStarted && !firstTime) {
		uiState.buttonText = 'start-again';
	}

	const variant = !isPrimary ? 'secondary' : uiState.buttonVariant;
	const callTooltip =
		uiState.buttonText === 'start-again'
			? t('start-again-tooltip')
			: t('start-call-tooltip');

	return (
		<>
			{botIsSpeaking && <SpeakerSimpleHigh size={20} />}
			<Box
				p="1px"
				bg={uiState.buttonBorder}
				animation={botIsSpeaking ? `${pulse} 0.4s infinite` : ''}
				borderRadius="8px"
			>
				<Tooltip
					showArrow
					positioning={{ placement: 'top' }}
					disabled={callStatus === CallStatus.started}
					content={callTooltip}
				>
					<Button
						size="full"
						id="call-button"
						variant={variant}
						onClick={onCallerClick}
						icon={<Play weight="bold" size={14} />}
						iconPosition="end"
					>
						{t(uiState.buttonText)}
					</Button>
				</Tooltip>
			</Box>
		</>
	);
};
