import { Text } from '@jam/front-library';
import React from 'react';

export const UserEmailCell: React.FC<{
	row: {
		email?: string | null;
	};
}> = ({ row }) => {
	return (
		<Text fontWeight={'medium'} variant="small-print">
			{row.email}
		</Text>
	);
};
