import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { MenuDivider } from './MenuDivider';
import { SidebarEntrySection } from './SideBarNavigation';
import { SideBarNavigationItem } from './SideBarNavigationItem';

interface Props<DataType> {
	isOpen: boolean;
	section: SidebarEntrySection<DataType>;
	sectionKey: string;
	data: DataType;
	onEventClick: (eventName: string) => void;
}

export const SideBarNavigationSection = <DataType,>({
	isOpen,
	data,
	sectionKey,
	section,
	onEventClick,
}: Props<DataType>) => {
	const { t } = useTranslation('common');
	const { label, entries, hidden, isFooterSection } = section;

	if (hidden?.(data)) return null;

	return (
		<Stack key={sectionKey} gap="1" mt={isFooterSection ? 'auto' : undefined}>
			{label && <MenuDivider text={isOpen ? t(label) : ''} />}
			{entries.map((item, index) => {
				return (
					<SideBarNavigationItem
						key={index}
						item={item}
						itemKey={index.toString()}
						isOpen={isOpen}
						data={data}
						onEventClick={onEventClick}
					/>
				);
			})}
		</Stack>
	);
};
