import { Flex, Input } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { SchemaOrganizationResponseDto } from '@jam/api-sdk/api';
import {
	Button,
	DialogBackdrop,
	DialogBody,
	DialogCloseTrigger,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogRoot,
	DialogTitle,
	Field,
	Select,
	Text,
} from '@jam/front-library';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useGetSagasQuery } from '../../redux/api/adminApi';

const orgSchema = z.object({
	id: z.string(),
	name: z.string().min(1),
	sagaIds: z.array(z.string()),
});

type Org = z.infer<typeof orgSchema>;

export const OrganizationEditModal = ({
	org,
	isOpen,
	onClose,
	onSubmit,
}: {
	org: SchemaOrganizationResponseDto;
	isOpen: boolean;
	onClose: () => void;
	onSubmit: (data: Org) => Promise<void>;
}) => {
	const contentRef = React.useRef<HTMLDivElement>(null);
	const {
		register,
		handleSubmit,
		reset,
		control,
		formState: { isValid, isSubmitting },
	} = useForm<Org>({
		resolver: zodResolver(orgSchema),
		defaultValues: org,
	});
	const { data: sagasData } = useGetSagasQuery();

	useEffect(() => {
		reset(org);
	}, [org]);

	const onCloseHandler = () => {
		reset(org);
		onClose();
	};

	const options = (sagasData ?? []).map((saga) => ({
		label: saga.name,
		value: saga.id,
	}));

	return (
		<DialogRoot
			role={'dialog'}
			size={'lg'}
			open={isOpen}
			onOpenChange={({ open }) => {
				if (!open) {
					onCloseHandler();
				}
			}}
		>
			<DialogBackdrop />
			<DialogContent ref={contentRef}>
				<DialogHeader>
					<DialogTitle fontSize={'2xl'}>Edit organization</DialogTitle>
				</DialogHeader>
				<DialogCloseTrigger />
				<DialogBody pb={5}>
					<input type="hidden" {...register('id')} />
					<Flex gap={2} direction="column">
						<Field as="fieldset" label={'Name'}>
							<Input {...register('name')} />
						</Field>
						<Controller
							control={control}
							name="sagaIds"
							render={({ field }) => (
								<Field as="fieldset" label={'Sagas'}>
									<Select
										portalRef={contentRef}
										name={field.name}
										value={field.value}
										onValueChange={(value) => field.onChange(value)}
										borderRadius={'full'}
										items={options}
										multiple
									/>
								</Field>
							)}
						/>
					</Flex>
				</DialogBody>
				<DialogFooter>
					<Button
						id="submit-org-data"
						type="submit"
						loading={isSubmitting}
						disabled={!isValid}
						onClick={() => {
							if (!isValid) return;

							void handleSubmit(onSubmit)();
							onCloseHandler();
						}}
					>
						Submit
					</Button>
					<Button
						variant="tertiary"
						onClick={() => {
							onCloseHandler();
						}}
					>
						<Text>Cancel</Text>
					</Button>
				</DialogFooter>
			</DialogContent>
		</DialogRoot>
	);
};
