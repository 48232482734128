import { Flex, Stack, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Language } from '@jam/api-sdk';
import { useAuthInfo } from '@propelauth/react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z, ZodType } from 'zod';
import { useChangeLanguageMutation } from '../../../redux/api/crmApi';
import { logUserInformation } from '../../../utils/logger';
import { Button, Field, Select, toaster } from '@jam/front-library';

type LanguageForm = {
	language: Language;
};

type LanguageChangeFormProps = {
	language: Language;
};

const LanguageSchema: ZodType<LanguageForm> = z.object({
	language: z.enum(['en', 'de']),
});

export const LanguageChangeForm = (props: LanguageChangeFormProps) => {
	const { t } = useTranslation('settings');
	const [changeLanguage] = useChangeLanguageMutation();
	const { refreshAuthInfo } = useAuthInfo();
	const {
		handleSubmit,
		reset,
		formState: { isSubmitting },
		control,
	} = useForm<LanguageForm>({
		resolver: zodResolver(LanguageSchema),
		defaultValues: {
			language: props.language,
		},
	});

	const onSubmit = async (data: LanguageForm) => {
		await changeLanguage({ language: data.language });
		await refreshAuthInfo();
		logUserInformation({
			language: data.language,
		});
		toaster.create({
			title: t('language_change_success'),
			type: 'success',
			meta: {
				closeable: true,
			},
		});
	};

	return (
		<Flex direction={'column'}>
			<Text fontWeight={'medium'} fontSize={'24px'} color={'#757575'}>
				{t('language')}
			</Text>
			<Stack mt="5" gap={4} maxW={'500px'}>
				<Controller
					control={control}
					name="language"
					render={({ field }) => (
						<Field as="fieldset">
							<Select
								name={field.name}
								value={field.value}
								onValueChange={(value) => field.onChange(value)}
								items={[
									{ value: 'en', label: 'English' },
									{ value: 'de', label: 'German' },
								]}
							/>
						</Field>
					)}
				/>

				<Flex mt="4" gap="5">
					<Button
						id="save-language-button"
						type="submit"
						loading={isSubmitting}
						size="sm"
						onClick={() => {
							void handleSubmit(onSubmit)();
						}}
					>
						{t('save')}
					</Button>
					<Button
						variant="tertiary"
						type="reset"
						size="sm"
						onClick={() => {
							reset();
						}}
					>
						{t('cancel')}
					</Button>
				</Flex>
			</Stack>
		</Flex>
	);
};
