import { Text } from '@jam/front-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { dateAgoAsString } from '../../../../utils/dateUtil';

export const CreatedAtCell: React.FC<{
	row: {
		createdAt: string;
	};
}> = ({ row }) => {
	const { t } = useTranslation();
	if (!row.createdAt) return null;
	return <Text variant="small-print">{dateAgoAsString(t, row.createdAt)}</Text>;
};
