import { useTour } from '@reactour/tour';
import { useLayoutEffect } from 'react';

export const useOnboardingStepFallback = () => {
	const { currentStep, steps, setSteps } = useTour();

	useLayoutEffect(() => {
		if (
			!steps?.length ||
			currentStep === null ||
			currentStep === undefined ||
			!steps[currentStep]
		) {
			return;
		}

		const selector = steps[currentStep].selector as string;

		const exists = document.querySelector(selector);

		if (!exists) {
			const newSelector = 'body';
			setSteps?.((prevSteps) => {
				const newSteps = [...prevSteps];
				newSteps[currentStep].selector = newSelector;
				return newSteps;
			});
		}
	}, [currentStep, steps]);
};
