import {
	Button,
	DialogBackdrop,
	DialogBody,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogRoot,
	DialogTitle,
} from '@jam/front-library';
import { useTranslation } from 'react-i18next';

type CallTooShortDialogProps = {
	isOpen: boolean;
	onCancelFeedback: () => void;
	onGenerateFeedback: () => void;
};

const CallTooShortDialog = ({
	isOpen,
	onCancelFeedback,
	onGenerateFeedback,
}: CallTooShortDialogProps) => {
	const { t } = useTranslation('call');
	return (
		<DialogRoot
			size={'xl'}
			open={isOpen}
			onOpenChange={({ open }) => {
				if (!open) {
					onCancelFeedback();
				}
			}}
		>
			<DialogBackdrop />
			<DialogContent>
				<DialogHeader>
					<DialogTitle fontSize="lg" fontWeight="bold">
						{t('callTooShortDialog.title')}
					</DialogTitle>
				</DialogHeader>

				<DialogBody>{t('callTooShortDialog.text')}</DialogBody>

				<DialogFooter>
					<Button
						id="generate-feedback-call-too-short-dialog-button"
						onClick={onGenerateFeedback}
					>
						{t('callTooShortDialog.generate_button')}
					</Button>
					<Button
						id="cancel-call-too-short-dialog-button"
						variant={'tertiary'}
						onClick={onCancelFeedback}
					>
						{t('callTooShortDialog.cancel_button')}
					</Button>
				</DialogFooter>
			</DialogContent>
		</DialogRoot>
	);
};

export default CallTooShortDialog;
