import { Flex } from '@chakra-ui/react';
import { Text } from '@jam/front-library';
import React from 'react';

export interface BasePageHeaderProps {
	title: string;
	subtitles?: string[];
	titleActions?: React.ReactNode;
	fullWidthLayout?: boolean;
}
export const BasePageHeader: React.FC<BasePageHeaderProps> = ({
	title,
	subtitles,
	titleActions,
	fullWidthLayout = false,
}) => {
	return (
		<Flex
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			width="100%"
			px={fullWidthLayout ? 10 : 'initial'}
		>
			<Flex gap="4" direction="column" width="100%">
				<Text fontSize="3xl" fontWeight="medium">
					{title}
				</Text>
				{subtitles?.length && (
					<Flex direction="row" gap={2} width="full">
						{subtitles?.filter(Boolean)?.map((subtitle, index) => {
							return (
								<>
									<Text key={subtitle} fontSize="16px" color="#757575">
										{subtitle}
									</Text>
									{index !== subtitles.length - 1 && (
										<Text fontSize="16px" color="#757575">
											•
										</Text>
									)}
								</>
							);
						})}
					</Flex>
				)}
			</Flex>
			{titleActions}
		</Flex>
	);
};
